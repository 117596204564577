import { MDBCol, MDBRow } from "mdbreact";
import ProgressButton from "./ProgressButton";
import {
  downloadIdCardText,
  errorMessageText,
  idCardExpiredText,
  okText,
} from "../../core/constants/strings";
import Skeleton from "react-loading-skeleton";
import React, { FunctionComponent } from "react";
import { useAuth } from "../../core/hooks/useAuth";
import { useDownloadIdCard } from "../../core/hooks/useDownloadIdCard";
import { isLeft } from "../../core/types/Either";
import AlertModal from "./AlertModal";
import useToggle from "react-use-toggle";
import { Plan } from "../../domain/entity/Plan";
import useGTM from "@elgorditosalsero/react-gtm-hook";

interface ProgressButtonProps {
  plan?: Plan;
}

const IdCardDownloadButton: FunctionComponent<ProgressButtonProps> = (
  props
) => {
  const auth = useAuth();
  const { sendDataToGTM } = useGTM();
  const downloadIdCard = useDownloadIdCard();
  const [
    isIdCardDownloadErrorModalOpen,
    setIdCardDownloadErrorModalOpen,
  ] = useToggle(false);

  function doDownloadIdCard() {
    downloadIdCard
      .download(
        props.plan?.appId,
        props.plan?.productType,
        props.plan?.isLegacyApp
      )
      .then((result) => {
        if (isLeft(result)) {
          setIdCardDownloadErrorModalOpen(true);
          sendDataToGTM({ event: "download_id_card", value: "success" });
        } else {
          sendDataToGTM({ event: "download_id_card", value: "error" });
        }
      });
  }

  return (
    <>
      <MDBRow>
        <MDBCol>
          {!auth.gettingApplication ? (
            props.plan ? (
              <ProgressButton
                color="orange"
                block
                className="btn-md font-weight-semi-bold text-capitalize mb-3 px-3"
                text={downloadIdCardText}
                loading={downloadIdCard.isDownloading}
                onClick={doDownloadIdCard}
                id="download-id-card"
              ></ProgressButton>
            ) : (
              <p>{idCardExpiredText}</p>
            )
          ) : (
            <Skeleton height="3.1rem" />
          )}
        </MDBCol>
      </MDBRow>
      <AlertModal
        isOpen={isIdCardDownloadErrorModalOpen}
        title={downloadIdCardText}
        body={errorMessageText}
        positive={okText}
        onPositive={setIdCardDownloadErrorModalOpen}
        onDismiss={setIdCardDownloadErrorModalOpen}
      />
    </>
  );
};

export default IdCardDownloadButton;

import UseCase from "../../core/useCase/UseCase";
import { PasswordRepository } from "../repository/PasswordRepository";

export default class ResetPassword extends UseCase<any, ResetPasswordParams> {
  repository: PasswordRepository;

  constructor(repository: PasswordRepository) {
    super();
    this.repository = repository;
  }

  execute = (params: ResetPasswordParams) =>
    this.repository.reset(params.email);
}

export class ResetPasswordParams {
  email: string;

  constructor(email: string) {
    this.email = email;
  }
}

import {
  documentDownloadUrl,
  documentsListUrl,
} from "../../core/constants/endpoints";
import { DocumentModel } from "../model/DocumentModel";
import HttpClient from "../../core/helpers/HttpClient";

export abstract class DocumentRemoteDataSource {
  abstract getDocuments(): Promise<DocumentModel[]>;

  abstract getDocument(
    id?: number,
    appId?: string,
    isLegacyApp?: boolean
  ): Promise<Blob>;
}

export class DocumentRemoteDataSourceImpl extends DocumentRemoteDataSource {
  httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    super();
    this.httpClient = httpClient;
  }

  getDocuments = () =>
    this.httpClient.request(documentsListUrl, {
      method: "post",
      factory: (data) => data.map((it) => DocumentModel.fromJson(it)),
    });

  getDocument = (id?: number, appId?: string, isLegacyApp?: boolean) =>
    this.httpClient.request(documentDownloadUrl, {
      data: {
        documentId: id,
        appId: appId,
        isLegacyApp: isLegacyApp,
      },
      responseType: "blob",
      factory: (data) => new Blob([data], { type: "application/pdf" }),
    });
}

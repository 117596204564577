import FormatHelper from "../../core/helpers/FormatHelper";

export class User {
  readonly id: string;
  readonly email: string;
  readonly firstName: string;
  readonly middleInitial: string;
  readonly lastName: string;
  readonly phone: string;
  readonly phoneAlternate: string;
  readonly active: number;
  readonly address: string;
  readonly city: string;
  readonly state: string;
  readonly zip: string;
  readonly passwordResetFlag: number;
  readonly lastLoginDateTime: string;
  readonly newUser: boolean;
  readonly loggedInFromAdmin: boolean;
  readonly parsedPhoneNumber: string;

  constructor(
    id: string,
    email: string,
    firstName: string,
    middleInitial: string,
    lastName: string,
    phone: string,
    phoneAlternate: string,
    active: number,
    address: string,
    city: string,
    state: string,
    zip: string,
    passwordResetFlag: number,
    lastLoginDateTime: string,
    newUser: boolean,
    loggedInFromAdmin: boolean
  ) {
    this.id = id;
    this.email = email;
    this.firstName = firstName;
    this.middleInitial = middleInitial;
    this.lastName = lastName;
    this.phone = phone;
    this.phoneAlternate = phoneAlternate;
    this.active = active;
    this.address = address;
    this.city = city;
    this.state = state;
    this.zip = zip;
    this.passwordResetFlag = passwordResetFlag;
    this.lastLoginDateTime = lastLoginDateTime;
    this.newUser = newUser;
    this.loggedInFromAdmin = loggedInFromAdmin;

    // Phone number
    this.parsedPhoneNumber = new FormatHelper().phoneNumberFormat(this.phone);
  }
}

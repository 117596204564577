export class Benefit {
  readonly deductible: string;
  readonly coinsurance: string;
  readonly lifetimeMax: string;
  readonly outOfPocketMax: string;
  readonly majorServices: string;
  readonly basicServices: string;
  readonly preventiveDiagnostic: string;
  readonly benefitYearMaximum: string;
  readonly orthodontia: string;
  readonly allowedAmounts: string;
  readonly message: string;
  readonly hospitalInjury: string;
  readonly hospitalConfinement: string;
  readonly physicianOffice: string;
  readonly wellnessBenefits: string;
  readonly genericBrandNamePrescription: string;
  readonly patientAdvocacyServices: String;

  constructor(
    deductible: string,
    coinsurance: string,
    lifetimeMax: string,
    outOfPocketMax: string,
    majorServices: string,
    basicServices: string,
    preventiveDiagnostic: string,
    benefitYearMaximum: string,
    orthodontia: string,
    allowedAmounts: string,
    message: string,
    hospitalInjury: string,
    hospitalConfinement: string,
    physicianOffice: string,
    wellnessBenefits: string,
    genericBrandNamePrescription: string,
    patientAdvocacyServices: string
  ) {
    this.deductible = deductible;
    this.coinsurance = coinsurance;
    this.lifetimeMax = lifetimeMax;
    this.outOfPocketMax = outOfPocketMax;
    this.majorServices = majorServices;
    this.basicServices = basicServices;
    this.preventiveDiagnostic = preventiveDiagnostic;
    this.benefitYearMaximum = benefitYearMaximum;
    this.orthodontia = orthodontia;
    this.allowedAmounts = allowedAmounts;
    this.message = message;
    this.hospitalInjury = hospitalInjury;
    this.hospitalConfinement = hospitalConfinement;
    this.physicianOffice = physicianOffice;
    this.wellnessBenefits = wellnessBenefits;
    this.genericBrandNamePrescription = genericBrandNamePrescription;
    this.patientAdvocacyServices = patientAdvocacyServices;
  }
}

import { Either } from "../../core/types/Either";
import { Application } from "../entity/Application";

export abstract class ApplicationRepository {
  abstract getApplication(): Promise<Either<Error, Application>>;

  abstract getIbaLink(
    appId?: number,
    productType?: string
  ): Promise<Either<Error, string>>;
}

import UseCase from "../../core/useCase/UseCase";
import { IdCardRepository } from "../repository/IdCardRepository";

export default class GetIdCard extends UseCase<Blob, GetIdCardParams> {
  repository: IdCardRepository;

  constructor(repository: IdCardRepository) {
    super();
    this.repository = repository;
  }

  execute = (params: GetIdCardParams) =>
    this.repository.getIdCard(
      params.appId,
      params.productType,
      params.isLegacyApp
    );
}

export class GetIdCardParams {
  appId?: number;
  productType?: string;
  isLegacyApp?: boolean;

  constructor(appId?: number, productType?: string, isLegacyApp?: boolean) {
    this.appId = appId;
    this.productType = productType;
    this.isLegacyApp = isLegacyApp;
  }
}

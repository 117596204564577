import { Either, Left, Right } from "../../core/types/Either";
import { ApplicationRepository } from "../../domain/repository/ApplicationRepository";
import { Application } from "../../domain/entity/Application";
import { ApplicationRemoteDataSource } from "../dataSource/ApplicationRemoteDataSource";

export class ApplicationRepositoryImpl extends ApplicationRepository {
  remoteDataSource: ApplicationRemoteDataSource;

  constructor(remoteDataSource: ApplicationRemoteDataSource) {
    super();
    this.remoteDataSource = remoteDataSource;
  }

  async getApplication(): Promise<Either<Error, Application>> {
    try {
      return Right(await this.remoteDataSource.getApplication());
    } catch (e) {
      return Left(e);
    }
  }

  async getIbaLink(
    appId?: number,
    productType?: string
  ): Promise<Either<Error, string>> {
    try {
      return Right(await this.remoteDataSource.getIbaLink(appId, productType));
    } catch (e) {
      return Left(e);
    }
  }
}

import React, { useEffect, useRef } from "react";
import { usePivotMobileBanner } from "../../../core/hooks/usePivotMobileBanner";

const MobileBanner = () => {
  const divRef = useRef<HTMLDivElement>(null);
  const pivotMobileBanner = usePivotMobileBanner();

  useEffect(() => {
    const banner = pivotMobileBanner?.banner;

    if (banner) {
      banner.parentNode?.removeChild(banner);
      banner.style.display = "block";
      divRef.current?.appendChild(banner);
    }

    return () => {
      if (banner) {
        banner.style.display = "none";
        document.body.appendChild(banner);
      }
    };
  }, [pivotMobileBanner]);

  return <div id="banner" ref={divRef} />;
};

export default MobileBanner;

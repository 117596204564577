/**
 * A class representing the contents of a document attestation
 * @param userId The user ID associated with the attestation
 * @param attestationDate The date (as a string) when the attestation was submitted
 * @param attested Whether the user has attested
 */
export class Attestation {
  readonly userId?: number;
  readonly attestationDate?: string;
  readonly attested?: boolean;

  constructor(userId?: number, attestationDate?: string, attested?: boolean) {
    this.userId = userId;
    this.attestationDate = attestationDate;
    this.attested = attested;
  }
}

import UseCase from "../../core/useCase/UseCase";
import { ApplicationRepository } from "../repository/ApplicationRepository";

export default class GetIbaLink extends UseCase<string, GetIbaLinkParams> {
  repository: ApplicationRepository;

  constructor(repository: ApplicationRepository) {
    super();
    this.repository = repository;
  }

  execute = (params: GetIbaLinkParams) =>
    this.repository.getIbaLink(params.appId, params.productType);
}

export class GetIbaLinkParams {
  appId?: number;
  productType?: string;

  constructor(appId?: number, productType?: string) {
    this.appId = appId;
    this.productType = productType;
  }
}

import UseCase, { NoParams } from "../../core/useCase/UseCase";
import { ContactInformation } from "../entity/ContactInformation";
import { ContactInformationRepository } from "../repository/ContactInformationRepository";

export default class GetContactInformation extends UseCase<
  ContactInformation,
  NoParams
> {
  repository: ContactInformationRepository;

  constructor(repository: ContactInformationRepository) {
    super();
    this.repository = repository;
  }

  execute = () => this.repository.getContactInformation();
}

import { getQuoteUrl } from "../../core/constants/endpoints";
import HttpClient from "../../core/helpers/HttpClient";
import { QuoteModel } from "../model/QuoteModel";

export abstract class QuoteRemoteDataSource {
  abstract getQuotes(): Promise<Array<QuoteModel>>;
}

export class QuoteRemoteDataSourceImpl extends QuoteRemoteDataSource {
  httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    super();
    this.httpClient = httpClient;
  }

  getQuotes = () =>
    this.httpClient.request(getQuoteUrl, {
      factory: (data) => {
        if (data?.products) {
          return data.products.map((item) => QuoteModel.fromJson(item));
        }
        return [];
      },
    });
}

import { Either, Left, Right } from "../../core/types/Either";
import { QuoteRepository } from "../../domain/repository/QuoteRepository";
import { Quote } from "../../domain/entity/Quote";
import { QuoteRemoteDataSource } from "../dataSource/QuoteRemoteDataSource";

export class QuoteRepositoryImpl extends QuoteRepository {
  remoteDataSource: QuoteRemoteDataSource;

  constructor(remoteDataSource: QuoteRemoteDataSource) {
    super();
    this.remoteDataSource = remoteDataSource;
  }

  async getQuotes(): Promise<Either<Error, Array<Quote>>> {
    try {
      return Right(await this.remoteDataSource.getQuotes());
    } catch (e) {
      return Left(e);
    }
  }
}

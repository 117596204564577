import { AddOn } from "./AddOn";
import { Member } from "./Member";
import { Benefit } from "./Benefit";
import {
  agenciesList,
  patientAdvocacyServicesBenefit,
} from "../../core/constants/strings";
import { enrollAgentUrl, enrollUrl } from "../../core/constants/endpoints";
import { Plan } from "./Plan";

export class Application {
  //#region properties

  readonly id: number;
  readonly expirationDate?: string;
  readonly appId?: number;
  readonly carrierId?: string;
  readonly productType?: string;
  readonly packageName?: string;
  readonly type?: string;
  readonly state?: string;
  readonly active?: boolean;
  readonly addOns?: AddOn[];
  readonly members?: Member[];
  readonly benefit?: Benefit;
  readonly policyId?: string;
  readonly agentId?: string;
  readonly agencyId?: string;
  readonly isLegacyApp?: boolean;
  readonly agentPhone?: string;
  readonly agentPhoneMobile?: string;
  readonly channelName?: string;
  readonly networkProvider?:
    | "cigna"
    | "first-health"
    | "any-provider"
    | undefined;
  readonly ibaLink?: string;
  readonly dentalPlan?: AddOn;
  readonly visionPlan?: AddOn;
  readonly latitudePlan?: AddOn;
  readonly expirationDays?: number;
  readonly email?: string;
  readonly zip?: string;
  readonly shouldEnrollAgain?: boolean;
  readonly isFromEnrollPage?: boolean;
  readonly enrollNowUrl?: string;
  readonly benefitList?: String[];
  readonly primaryMember?: Member;
  readonly hasSpouse?: boolean;
  readonly membersType?: string;
  readonly plans?: Plan[] = [];
  readonly session?: string;
  readonly isTelemedicineAvailable?: boolean;
  readonly isPointHealthFooterAvailable?: boolean;
  readonly planId?: string;
  readonly effectiveDate?: string;
  readonly orderDate?: string;
  readonly isISC?: boolean;
  readonly isDTC?: boolean;
  readonly isBrokerage?: boolean;
  //#endregion

  //#endregion methods

  constructor(
    id: number,
    expirationDate?: string,
    appId?: number,
    carrierId?: string,
    productType?: string,
    packageName?: string,
    type?: string,
    state?: string,
    active?: boolean,
    addOns?: AddOn[],
    members?: Member[],
    benefit?: Benefit,
    policyId?: string,
    agentId?: string,
    agencyId?: string,
    isLegacyApp?: boolean,
    agentPhone?: string,
    agentPhoneMobile?: string,
    networkProvider?: "cigna" | "first-health" | "any-provider" | undefined,
    ibaLink?: string,
    expirationDays?: number,
    email?: string,
    zip?: string,
    session?: string,
    planId?: string,
    effectiveDate?: string,
    orderDate?: string,
    channelName?: string
  ) {
    this.id = id;
    this.expirationDate = expirationDate;
    this.appId = appId;
    this.carrierId = carrierId;
    this.productType = productType;
    this.packageName = packageName;
    this.type = type;
    this.state = state;
    this.active = active;
    this.addOns = addOns;
    this.members = members;
    this.benefit = benefit;
    this.policyId = policyId;
    this.agentId = agentId;
    this.agencyId = agencyId;
    this.isLegacyApp = isLegacyApp;
    this.agentPhone = agentPhone;
    this.agentPhoneMobile = agentPhoneMobile;
    this.channelName = channelName;
    this.networkProvider = networkProvider;
    this.ibaLink = ibaLink;
    this.expirationDays = expirationDays;
    this.email = email;
    this.zip = zip;
    this.session = session;
    this.planId = planId;
    this.effectiveDate = effectiveDate;
    this.orderDate = orderDate;
    //point health
    this.isPointHealthFooterAvailable = this.shouldDisplayPointHealthOption();

    //telemedicine
    this.isTelemedicineAvailable = this.shouldDisplayTelemedicineOption();

    //Plan types
    this.dentalPlan = this.addOns?.find((it) => it.productType === "DENTAL");
    this.visionPlan = this.addOns?.find((it) => it.productType === "VISION");
    this.latitudePlan = this.addOns?.find(
      (it) => it.productType === "LATITUDE"
    );

    // Primary member
    this.primaryMember = this.members?.find(
      (it) => it.relationship === "PRIMARY"
    );

    this.isBrokerage = this.channelName === "brokerage";
    this.isDTC = this.channelName === "dtc";
    this.isISC = this.channelName === "indy sales center";

    //Enroll again
    this.shouldEnrollAgain = !this.active || (this.expirationDays || 11) <= 10;
    this.isFromEnrollPage = agenciesList.includes(this.agencyId || "");
    this.enrollNowUrl = this.createEnrollNowUrl();

    // Members type
    let hasChildren = this.members?.find((it) => it.relationship === "CHILD");
    let hasSpouse = this.members?.find((it) => it.relationship === "SPOUSE");

    this.hasSpouse = Boolean(hasSpouse);
    this.membersType = hasChildren ? "family" : hasSpouse ? "couple" : "single";

    // Plans
    this.benefitList = this.getBenefitList();
    if (this.active) {
      this.plans?.push(
        new Plan(
          this.id,
          this.appId,
          this.productType,
          this.isLegacyApp,
          this.active
        )
      );
    }
    this.addOns
      ?.filter((it) => it.productType !== "LATITUDE" && it.active)
      ?.forEach((it) =>
        this.plans?.push(
          new Plan(
            it?.id,
            it?.appId,
            it?.productType,
            it?.isLegacyApp,
            it?.active
          )
        )
      );
  }

  private getBenefitList(): string[] | undefined {
    var benefitList = Object.keys(this.benefit ?? "")
      .filter((key) => {
        if (this.benefit) {
          if (key !== "patientAdvocacyServices") {
            return this.benefit[key];
          }
        }
        return undefined;
      })
      .map((key) => (this.benefit ? this.benefit[key] : []));

    if (benefitList.length === 0) {
      return undefined;
    }

    if (this.shouldDisplayPointHealthOption()) {
      benefitList.push(patientAdvocacyServicesBenefit);
    }

    return benefitList;
  }

  private createEnrollNowUrl(): string {
    let memberParams = `dob=${this.primaryMember?.dob}&gender=${
      this.primaryMember?.gender
    }&first_name=${this.primaryMember?.firstName}&subId1=RENEWAL&subId2=::::${
      this.session || ""
    }:::`;
    let utmParams = `utm_campaign=member_portal&utm_source=cross_sell_enroll&utm_medium=organic`;
    if (this.isFromEnrollPage)
      return `${enrollUrl}?${memberParams}&${utmParams}`;
    else
      return `${enrollAgentUrl}?agent_id=${this.agentId}&${memberParams}&${utmParams}`;
  }

  private shouldDisplayTelemedicineOption(): boolean {
    if (this.planId?.includes("EPIC") || this.type === "FBM") {
      return true;
    } else {
      if (
        this.carrierId === "CLIC" &&
        (this.type === "STM" || this.type === "BTM")
      ) {
        var startTelemedicine = new Date("2021-06-16 00:00:00.000");
        var date = new Date(this.effectiveDate ?? Date.now());
        if (date >= startTelemedicine) {
          return true;
        }
      }
    }
    return false;
  }

  private shouldDisplayPointHealthOption(): boolean {
    var datetimeCreatedFormat = new Date(this.orderDate ?? "");
    var benefitStartDate = new Date("2021-12-05 23:59:59.000");
    if (this.type == "BTM" || this.type == "STM" || this.type == "FBM") {
      return (
        datetimeCreatedFormat > benefitStartDate &&
        !(this.planId?.includes("QUA") || this.planId?.includes("PP"))
      );
    }
    return false;
  }
  //#endregion
}

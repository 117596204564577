import UseCase from "../../core/useCase/UseCase";
import { UserRepository } from "../repository/UserRepository";

export default class Login extends UseCase<string, LoginParams> {
  repository: UserRepository;

  constructor(repository: UserRepository) {
    super();
    this.repository = repository;
  }

  execute = (params: LoginParams) =>
    this.repository.login(params.email, params.password);
}

export class LoginParams {
  email: string;
  password: string;

  constructor(email: string, password: string) {
    this.email = email;
    this.password = password;
  }
}

import { environment } from "./environment";

export const pivotHealthUrl = `https://${environment || "www."}pivothealth.com`;
export const privacyPolicyUrl = `${pivotHealthUrl}/privacy`;
export const termsOfServiceUrl = `${pivotHealthUrl}/terms-of-service`;
export const contactUsUrl = `${pivotHealthUrl}/contact`;
export const authUrl = `https://auth.${environment}pivothealth.com`;
export const loginUrl = `${authUrl}/service/users/login?role=MEMBER`;
export const memberUrl = `${authUrl}/service/members`;
export const resetPasswordUrl = `${authUrl}/service/members/reset_password`;
export const changePasswordUrl = `${authUrl}/service/members/change_password`;
export const updatePasswordUrl = `${authUrl}/service/members/change_password_authenticated`;
export const pivotAuthUrl = `${authUrl}/service/users/`;
export const mobileUrl = `https://mobile-api.${environment}pivothealth.com`;
export const applicationsUrl = `${mobileUrl}/mobile/members/applications`;
export const ibaLinkUrl = `${mobileUrl}/mobile/members/applications/iba_link`;
export const enrollUrl = `https://enroll.${environment}pivothealth.com/census`;
export const enrollAgentUrl = `${pivotHealthUrl}/product/short-term-health-insurance/census`;
export const documentDownloadUrl = `${mobileUrl}/mobile/members/applications/documents/download`;
export const documentsListUrl = `${mobileUrl}/mobile/members/applications/documents`;
export const contactInformationUrl = `${mobileUrl}/contact-forms/16`;
export const getQuoteUrl = `${mobileUrl}/mobile/members/applications/v2/quote_stand_alone`;
export const dentalAndVisionProductUrl = `${pivotHealthUrl}/product/dental-and-vision/census`;
export const latitudeProductUrl = `${pivotHealthUrl}/product/supplemental-health-insurance/plans`;

export const cignaUrl =
  "https://hcpdirectory.cigna.com/web/public/consumer/directory/search?consumerCode=HDC001";
export const firstHealthUrl =
  "https://www.firsthealthlbp.com/LocateProvider/SelectNetworkType";

export const dentalDoctorUrl =
  "https://www.myrenproviders.com/v3app/a/?6713520D04184E241C0D022C0D340C1C130C11166E221738130C0A0C1737483A0002181B1A18225A403E370111000A0017361A331706230A171747040051305A030F491351165B5D0C775F01445C0701425E75460367485C5C59555E187156590F4550514759585D3848140C1C130C11162816582C515558585110157F48590A100D4C405D5858680F540B5F4854404B2043546600070C5B5C0E07201D12051355584C585C5E005622515B21504546033155645C56285F274A140B55572D305B514C2F5E5A7C5D555B";
export const visionDoctorUrl = "https://www.vsp.com/eye-doctor";
export const latitudePlanDetailsUrl = `${pivotHealthUrl}/product/supplemental-health-insurance/plans/details.php`;

export const mobileAppBannerUrl = `https://assets.${environment}healthcare.com/mobile-app-banner/experiences/hc-mobile-app-banner-card.js`;

export const helpPhoneUrl = "tel:+{phone}";
export const helpEmailUrl = "mailto:{email}";
export const insuranceAdministratorsUrl = "tel:+18446307500";
export const insuranceAdminsitratorsEmail =
  "mailto:clientservices@insurancebenefitadministrators.com";

export const agentLoginUrl = `${pivotHealthUrl}/agent/admin/login/`;

export const dentalMembershipSummary =
  "https://cdn.pivothealth.com/resources/dental-and-vision/CA-Dental-Memebership-Summary-061719.pdf";
export const dentalPlanBrochureUrl =
  "https://cdn.pivothealth.com/resources/v2/products/dental/brochure/dental-res.pdf";

export const telemedicineUrl = "https://pivothealthepic@navigohealth.com/";

export const pointhealthUrl =
  "https://ab8p.adj.st/connect?adj_t=6497l39&adj_deep_link=pointhealth%3A%2F%2Fconnect&adj_fallback=https%3A%2F%2Fwww.pointhealth.com%2Fapp-store-directory&adj_redirect_macos=https%3A%2F%2Fwww.pointhealth.com%2Fapp-store-directory";

export const pointHealhSupportUrl =
  "https://www.pointhealth.com/member-support";

export const buyAflacUrl = `https://buy.aflac.com/partnerCampaign/AA31761/HealthCare_Default`;

import { CookieHelper } from "../../core/helpers/CookieHelper";

export abstract class UserLocalDataSource {
  abstract getToken(): string;

  abstract setToken(token: string): void;

  abstract unsetToken(): void;
}

export class UserLocalDataSourceImpl extends UserLocalDataSource {
  cookieHelper: CookieHelper;

  constructor(cookieHelper: CookieHelper) {
    super();
    this.cookieHelper = cookieHelper;
  }

  getToken = () => this.cookieHelper.getItem("token");

  setToken = (token: string) => this.cookieHelper.setItem("token", token, 1);

  unsetToken = () => this.cookieHelper.setItem("token", null, -1);
}

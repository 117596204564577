import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { ServerError, SessionExpiredError } from "../error/error";

export type Method =
  | "get"
  | "GET"
  | "delete"
  | "DELETE"
  | "head"
  | "HEAD"
  | "options"
  | "OPTIONS"
  | "post"
  | "POST"
  | "put"
  | "PUT"
  | "patch"
  | "PATCH"
  | "link"
  | "LINK"
  | "unlink"
  | "UNLINK";

export type ResponseType =
  | "arraybuffer"
  | "blob"
  | "document"
  | "json"
  | "text"
  | "stream";

export default class HttpClient {
  interceptor: number = 0;

  authenticateRequests = (token: string) => {
    axios.interceptors.request.eject(this.interceptor);
    this.interceptor = axios.interceptors.request.use((request) => {
      request.headers["Authorization"] = `Bearer ${token}`;
      return request;
    });
  };

  unauthenticateRequests = () => {
    axios.interceptors.request.eject(this.interceptor);
    this.interceptor = 0;
  };

  request: <T>(
    url,
    {
      data,
      method,
      headers,
      factory,
    }: {
      data?: any;
      method?: Method;
      headers?: any;
      responseType?: ResponseType;
      factory?: (data, headers) => T;
    }
  ) => Promise<T> = (url, config) => {
    const _config: AxiosRequestConfig = {
      url,
      method: config.method || config.data ? "post" : "get",
      headers: {
        "Content-Type": "application/json",
        ...config.headers,
      },
      responseType: config.responseType,
      data: config.data,
    };

    return axios
      .request(_config)
      .catch((result: AxiosError) => {
        if (result.response?.status === 401 && this.interceptor > 0) {
          this.unauthenticateRequests();
          window.location.reload();
          throw new SessionExpiredError();
        }

        throw new ServerError(result.message, result.response?.status);
      })
      .then(
        async (response) =>
          config.factory?.(await response.data, response.headers) ||
          (await response.data)
      );
  };
}

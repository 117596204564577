import UseCase, { NoParams } from "../../core/useCase/UseCase";
import { UserRepository } from "../repository/UserRepository";

export default class Logout extends UseCase<void, NoParams> {
  repository: UserRepository;

  constructor(repository: UserRepository) {
    super();
    this.repository = repository;
  }

  execute = () => this.repository.logout();
}

const executeWhenFinishToRender = () => {
  const functions = window.executeWhenFinishToRender || [];

  if (Array.isArray(functions)) {
    window.executeWhenFinishToRender = {
      push: (fun) => {
        try {
          setTimeout(function () {
            fun();
          }, 0);
        } catch (error) {
          console.warn(error); // eslint-disable-line
        }
      },
    };

    functions.forEach((func) => window.executeWhenFinishToRender.push(func));
  }
};

export default executeWhenFinishToRender;

import React, { FunctionComponent } from "react";
import { MDBCard, MDBCardBody, MDBCol, MDBRow } from "mdbreact";

export enum AFLACProductType {
  ACCIDENT = "Accident Insurance",
  CANCER = "Cancer Insurance",
  CRITICAL = "Critical Illness Insurance",
}

interface AdProps {
  icon: JSX.Element;
  text: JSX.Element;
  cta: JSX.Element;
}
const Ad: FunctionComponent<AdProps> = ({ text, icon, cta }) => {
  return (
    <MDBCard className="aflac d-flex flex-column flex-md-row align-items-center text-center mb-2">
      <MDBCardBody className="p-3 px-md-4">
        <MDBRow className="align-items-center">
          <MDBCol className="col-12 col-md-auto">{icon}</MDBCol>
          <MDBCol className="primary-text px-0 py-2 py-md-0 align-self-center text-md-left col-12 col-md flex-grow-1">
            {text}
          </MDBCol>
          <MDBCol className="col-12 col-md-4 col-xl-3  p-md-1">{cta}</MDBCol>
        </MDBRow>
      </MDBCardBody>
    </MDBCard>
  );
};

export default Ad;

import Ad from "./Ad";
import { MDBBtn, MDBCard } from "mdbreact";
import { useAuth } from "core/hooks/useAuth";
import React, { FunctionComponent } from "react";
import { buyAflacUrl } from "core/constants/endpoints";
import { ReactComponent as HeartIcon } from "assets/heart-icon.svg";
import { ReactComponent as RibbonIcon } from "assets/ribbon-icon.svg";
import { ReactComponent as AmbulanceIcon } from "assets/ambulance-icon.svg";
import Skeleton from "react-loading-skeleton";

const AflacAds: FunctionComponent = () => {
  const auth = useAuth();
  const application = auth?.application;

  const createAflacURL = (productName): string => {
    let coverageType = "ind";

    if (application?.hasSpouse) {
      coverageType =
        application?.membersType === "family" ? "ind_fml" : "ind_sps";
    } else if (application?.membersType === "family") {
      coverageType = "ind_chd";
    }

    const baseURL = buyAflacUrl;

    const parameters = [
      `productName=${productName}`,
      `state=${application?.state}`,
      `age=${application?.primaryMember?.age}`,
      `coverage=${coverageType}`,
      `utm_campaign=member_portal&utm_source=cross_sell_enroll&utm_medium=organic`,
    ];

    return `${baseURL}?${parameters.join("&")}`;
  };

  if (!(application?.isDTC || application?.isISC)) {
    return <></>;
  }

  return !auth.gettingApplication ? (
    <div className="aflac-ads" id="aflac-ads">
      <MDBCard
        className="p-4 mb-2 card-flat"
        style={{ backgroundColor: "#edf7ff" }}
      >
        <span className="primary-text font-weight-semi-bold h5-responsive">
          Aflac insurance policies available to you:{" "}
        </span>
      </MDBCard>
      <Ad
        text={
          <>
            <span className="font-weight-bold">Accident:</span> Support for
            unnexpected moments
          </>
        }
        cta={
          <MDBBtn
            color="orange"
            target="blank"
            href={createAflacURL("Accident Insurance")}
            className="btn-md font-weight-semi-bold w-md-100 m-0 px-4 py-2 text-capitalize"
          >
            Request a Quote
          </MDBBtn>
        }
        icon={<AmbulanceIcon height={56} />}
      ></Ad>
      <Ad
        text={
          <>
            <span className="font-weight-bold">Cancer:</span> Helps pay for
            expenses associated with a cancer diagnosis
          </>
        }
        cta={
          <MDBBtn
            color="orange"
            target="blank"
            href={createAflacURL("Cancer Insurance")}
            className="btn-md font-weight-semi-bold m-0 w-md-100 px-4 py-2 text-capitalize"
          >
            Request a Quote
          </MDBBtn>
        }
        icon={<RibbonIcon height={56} />}
      ></Ad>
      <Ad
        text={
          <>
            <span className="font-weight-bold">Critical Illness:</span> Pays
            cash upon diagnoses of a covered critical illness.
          </>
        }
        cta={
          <MDBBtn
            color="orange"
            target="blank"
            href={createAflacURL("Critical Illness Insurance")}
            className="btn-md font-weight-bold w-md-100 m-0 px-4 py-2 text-capitalize"
          >
            Request a Quote
          </MDBBtn>
        }
        icon={<HeartIcon height={56} />}
      ></Ad>
    </div>
  ) : (
    <>
      <Skeleton height="4.56rem" className="mb-3" />
      <Skeleton height="4.56rem" className="mb-3" />
      <Skeleton height="4.56rem" className="mb-3" />
    </>
  );
};

export default AflacAds;

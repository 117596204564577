import React, { useEffect, useState } from "react";
import { MDBAlert, MDBCol, MDBContainer, MDBRow, MDBView } from "mdbreact";
import loginImage from "../../assets/login.svg";
import {
  emailText,
  fieldRequiredText,
  greatText,
  incorrectEmailText,
  memberPortalText,
  oopsText,
  resetMyPasswordText,
  resetPasswordErrorText,
  resetPasswordSuccessText,
  resetPasswordText,
  welcomeToText,
} from "../../core/constants/strings";
import { isRight } from "../../core/types/Either";
import ProgressButton from "../components/ProgressButton";
import { useAuth } from "../../core/hooks/useAuth";
import { useForm } from "react-hook-form";
import useGTM from "@elgorditosalsero/react-gtm-hook";
import executeWhenFinishToRender from "../../core/functions/executeWhenFinishToRender";

const ForgotPasswordPage = (props) => {
  const auth = useAuth();
  const { sendDataToGTM } = useGTM();
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSucces] = useState(false);

  const { handleSubmit, register, errors, getValues } = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: true,
    defaultValues: {
      email: props?.location?.state?.email || "",
      password: "",
    },
  });

  const onSubmit = (values) => {
    setSubmitted(true);
    setLoading(true);
    auth
      .resetPassword(values.email)
      .then((result) => {
        if (isRight(result)) {
          setError(false);
          setSucces(true);
          sendDataToGTM({ event: "forgot_password", value: "success" });
        } else {
          setError(true);
          setSucces(false);
          sendDataToGTM({ event: "forgot_password", value: "error" });
        }
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => executeWhenFinishToRender(), []);

  return (
    <MDBView className="full py-3 py-md-5 ">
      <MDBContainer>
        <MDBRow className="justify-content-center">
          <MDBCol className="d-none d-md-block">
            <img className="img-fluid w-100" src={loginImage} alt="" />
          </MDBCol>
          <MDBCol size="10" md="5" className="px-3 px-md-0">
            <h3 className="h3-responsive mb-0 font-weight-normal">
              {welcomeToText}
            </h3>
            <h1 className="font-weight-semi-bold text-primary display-4">
              {memberPortalText}
            </h1>
            <h5 className="h5-responsive mb-4 font-weight-normal">
              {resetMyPasswordText}
            </h5>
            <form
              className={`needs-validation ${submitted ? "was-validated" : ""}`}
              onSubmit={handleSubmit(onSubmit)}
              noValidate
            >
              <label
                htmlFor="email"
                className="font-weight-semi-bold text-uppercase"
              >
                {emailText}
              </label>
              <input
                type="email"
                name="email"
                id="email"
                placeholder={emailText}
                className={`form-control${errors.email ? " is-invalid" : ""}`}
                ref={register({
                  required: fieldRequiredText,
                  pattern: {
                    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
                    message: incorrectEmailText,
                  },
                })}
              />
              <div className="invalid-feedback">{errors.email?.message}</div>
              {error && (
                <MDBAlert color="danger" className="mt-4">
                  <p className="mb-0">
                    <span className="text-danger font-weight-bold">
                      {oopsText}
                    </span>{" "}
                    {resetPasswordErrorText}
                  </p>
                </MDBAlert>
              )}
              {success && (
                <MDBAlert color="success" className="mt-4">
                  <p className="mb-0">
                    <span className="text-success font-weight-bold">
                      {greatText}
                    </span>{" "}
                    {resetPasswordSuccessText.replace(
                      "{email}",
                      getValues("email")
                    )}
                  </p>
                </MDBAlert>
              )}
              <div className="mt-4">
                <ProgressButton
                  className="btn-md font-weight-semi-bold text-capitalize"
                  block={true}
                  color="orange"
                  loading={loading}
                  text={resetPasswordText}
                />
              </div>
            </form>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </MDBView>
  );
};

export default ForgotPasswordPage;

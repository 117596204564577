import React, { FunctionComponent } from "react";
import { MDBCard, MDBCardBody, MDBCol, MDBRow } from "mdbreact";

interface QuoteLink {
  text: string;
  url: string;
}

interface QuoteProps {
  icon: FunctionComponent;
  title: string;
  enrollDisclaimerText?: string;
  learnMoreAboutBenefitsURL?: string;
  learnMoreAboutBenefitsText?: string;
  planName: string;
  price?: string;
  links?: Array<QuoteLink>;
  ctaDescriptionText: string;
  ctaButtonText: string;
  ctaURL: string;
}

const Quote: FunctionComponent<QuoteProps> = ({
  icon: QuoteIcon,
  ...props
}) => {
  const shouldDisplayLearnMoreRow =
    props.enrollDisclaimerText &&
    props.learnMoreAboutBenefitsText &&
    props.learnMoreAboutBenefitsURL;

  return (
    <MDBCard className="mb-3">
      <MDBCardBody className="p-3 px-lg-5">
        <MDBRow className="align-items-center">
          <MDBCol size="auto" className="d-flex justify-content-center">
            <QuoteIcon />
          </MDBCol>
          <MDBCol>
            <MDBRow>
              <MDBCol>
                <h4
                  className="mb-0 font-weight-semi-bold text-primary h4-responsive"
                  id="quote"
                >
                  {props.title}
                </h4>
              </MDBCol>
            </MDBRow>
            {shouldDisplayLearnMoreRow && (
              <MDBRow>
                <MDBCol>
                  <p className="mb-0 silver-text">
                    <small>
                      {props.enrollDisclaimerText}
                      <br />
                      <a
                        className="bg-transparent border-0 p-0 text-decoration-underline text-primary"
                        href={props.learnMoreAboutBenefitsURL}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {props.learnMoreAboutBenefitsText}
                      </a>
                    </small>
                  </p>
                </MDBCol>
              </MDBRow>
            )}
          </MDBCol>
        </MDBRow>
      </MDBCardBody>
      <hr className="m-0" />
      <MDBCardBody className="p-3 p-md-5">
        <MDBRow>
          <MDBCol className="col-12 col-md mb-3 mb-md-0 d-flex flex-wrap align-items-between text-center text-md-left">
            <MDBRow className="w-100">
              <MDBCol>
                <h4 className="font-weight-semi-bold h4-responsive text-center text-md-left">
                  {props.planName}
                </h4>
              </MDBCol>
            </MDBRow>

            <MDBRow className="w-100 justify-content-center justify-content-md-start align-items-center">
              {props.links?.map((link: QuoteLink, index: number) => (
                <MDBCol size="auto" className="pr-0 pr-md-3" key={index}>
                  <a
                    className="text-dark text-decoration-underline"
                    href={link.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {link.text}
                  </a>
                </MDBCol>
              ))}
            </MDBRow>
          </MDBCol>
          <MDBCol />
          <MDBCol className="col-12 col-md-auto text-center text-md-right">
            <p className="mb-0 font-weight-bold grey-text">
              {props.ctaDescriptionText}
            </p>
            {props.price ? (
              <h1 className="font-weight-semi-bold h2-responsive text-primary">
                ${props.price}/mo
              </h1>
            ) : (
              <div className="mb-3"></div>
            )}
            <a
              color="orange"
              className="btn btn-orange btn-block btn-md font-weight-semi-bold text-capitalize m-0 px-3"
              href="/"
              onClick={(e) => (e.currentTarget.href = props.ctaURL)}
              target="_blank"
              rel="noopener noreferrer"
              id="add-now"
            >
              {props.ctaButtonText}
            </a>
          </MDBCol>
        </MDBRow>
      </MDBCardBody>
    </MDBCard>
  );
};

export default Quote;
export type { QuoteLink };

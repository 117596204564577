import { MDBBtn, MDBCol, MDBRow } from "mdbreact";
import { eligibilityText, enrollNowText } from "../../core/constants/strings";
import React from "react";
import { useAuth } from "../../core/hooks/useAuth";

const EnrollAgainButton = () => {
  const auth = useAuth();

  return (
    <MDBRow>
      <MDBCol>
        {auth.application?.shouldEnrollAgain && (
          <>
            <MDBBtn
              color="orange"
              block
              className="btn-md font-weight-semi-bold text-capitalize mb-3"
              tag="a"
              href={`${auth.application.enrollNowUrl}&zip=${auth.user.zip}&email=${auth.user.email}`}
              target="_blank"
              id="enroll-now"
            >
              {enrollNowText}*
            </MDBBtn>
            <p className="silver-text mb-0">{eligibilityText}</p>
          </>
        )}
      </MDBCol>
    </MDBRow>
  );
};

export default EnrollAgainButton;

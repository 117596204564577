import React, { FunctionComponent } from "react";
import useToggle from "react-use-toggle";
import {
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
} from "mdbreact";

interface AlertModalProps {
  isOpen?: boolean;
  title?: string;
  body?: string;
  positive?: string;
  negative?: string;
  onPositive?: (e?: any) => void;
  onNegative?: (e?: any) => void;
  onDismiss?: (e?: any) => void;
}

const AlertModal: FunctionComponent<AlertModalProps> = (props) => {
  const [open, setOpen] = useToggle();

  React.useEffect(() => {
    if (props.isOpen) {
      setOpen();
    }
  }, [props.isOpen, setOpen]);

  function toggle() {
    setOpen();
    if (open) props.onDismiss?.();
  }

  function onNegative() {
    setOpen();
    props.onNegative?.();
  }

  function onPositive() {
    setOpen();
    props.onPositive?.();
  }

  return (
    <MDBModal
      isOpen={open}
      toggle={toggle}
      inline={false}
      noClickableBodyWithoutBackdrop={false}
      overflowScroll={true}
      centered
    >
      {props.title && (
        <MDBModalHeader toggle={toggle} className={"border-0"}>
          {props.title}
        </MDBModalHeader>
      )}
      {props.body && (
        <MDBModalBody className={"py-0"}>{props.body}</MDBModalBody>
      )}
      {(props.positive || props.negative) && (
        <MDBModalFooter className={"border-0"}>
          {props.negative && (
            <MDBBtn
              flat
              onClick={onNegative}
              color={"light"}
              className={"btn-flat grey-text px-3 py-2"}
            >
              {props.negative}
            </MDBBtn>
          )}
          {props.positive && (
            <MDBBtn
              onClick={onPositive}
              className={"btn-flat text-primary px-3 py-2"}
            >
              {props.positive}
            </MDBBtn>
          )}
        </MDBModalFooter>
      )}
    </MDBModal>
  );
};

AlertModal.defaultProps = {
  isOpen: false,
};

export default AlertModal;

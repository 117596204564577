import React, { useEffect, useState } from "react";
import { MDBAlert, MDBCol, MDBContainer, MDBRow, MDBView } from "mdbreact";
import loginImage from "../../assets/login.svg";
import {
  changePasswordErrorMessage,
  changePasswordHintText,
  changePasswordText,
  fieldRequiredText,
  incorrectPasswordText,
  memberPortalText,
  newPasswordConfirmationLabelText,
  newPasswordConfirmationText,
  newPasswordLabelText,
  newPasswordText,
  oopsText,
  passwordsDontMatchMessageText,
  passwordsDontMatchText,
  resetPasswordText,
  welcomeToText,
} from "../../core/constants/strings";
import ProgressButton from "../components/ProgressButton";
import { useAuth } from "../../core/hooks/useAuth";
import { useRouter } from "../../core/hooks/useRouter";
import { useForm } from "react-hook-form";
import { isRight } from "../../core/types/Either";
import useGTM from "@elgorditosalsero/react-gtm-hook";
import executeWhenFinishToRender from "../../core/functions/executeWhenFinishToRender";

const ChangePasswordPage = () => {
  const auth = useAuth();
  const router = useRouter();
  const { sendDataToGTM } = useGTM();
  const token = router.query.token;
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const { handleSubmit, register, errors, getValues } = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: true,
    defaultValues: {
      password: "",
      passwordConfirmation: "",
    },
  });

  const onSubmit = (values) => {
    setSubmitted(true);
    setLoading(true);
    auth
      .changePassword(token, values.password)
      .then((result) => {
        if (isRight(result)) {
          setError(false);
          router.push({
            pathname: "/login",
            state: {
              passwordChanged: true,
            },
          });
          sendDataToGTM({ event: "change_password", value: "success" });
        } else {
          setError(true);
          sendDataToGTM({ event: "change_password", value: "error" });
        }
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => executeWhenFinishToRender(), []);

  return (
    <MDBView className="full py-3 py-md-5 ">
      <MDBContainer>
        <MDBRow className="justify-content-center">
          <MDBCol className="d-none d-md-block">
            <img className="img-fluid w-100" src={loginImage} alt="" />
          </MDBCol>
          <MDBCol size="10" md="5" className="px-3 px-md-0">
            <h3 className="h3-responsive mb-0 font-weight-normal">
              {welcomeToText}
            </h3>
            <h1 className="font-weight-semi-bold text-primary display-4 tk-bree-serif">
              {memberPortalText}
            </h1>
            <h5 className="h5-responsive font-weight-normal">
              {changePasswordText}
            </h5>
            <p className="mb-4 font-weight-normal">{changePasswordHintText}</p>
            <form
              className={`needs-validation ${submitted ? "was-validated" : ""}`}
              onSubmit={handleSubmit(onSubmit)}
            >
              <label
                htmlFor="new-password"
                className="font-weight-semi-bold text-uppercase"
              >
                {newPasswordLabelText}
              </label>
              <input
                type="password"
                name="password"
                id="new-password"
                placeholder={newPasswordText}
                className={`form-control${
                  errors.password ? " is-invalid" : ""
                }`}
                ref={register({
                  required: fieldRequiredText,
                  pattern: {
                    value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
                    message: incorrectPasswordText,
                  },
                })}
              />
              <div className="invalid-feedback">{errors.password?.message}</div>
              <label
                htmlFor="new-password-confirmation"
                className="font-weight-semi-bold text-uppercase mt-4"
              >
                {newPasswordConfirmationLabelText}
              </label>
              <input
                type="password"
                name="passwordConfirmation"
                id="new-password-confirmation"
                placeholder={newPasswordConfirmationText}
                className={`form-control${
                  errors.password || errors.passwordConfirmation
                    ? " is-invalid"
                    : ""
                }`}
                ref={register({
                  required: fieldRequiredText,
                  validate: (value) =>
                    value === getValues("password")
                      ? true
                      : passwordsDontMatchText,
                })}
              />
              <div className="invalid-feedback">
                {errors.passwordConfirmation?.message}
              </div>
              {errors.password && (
                <MDBAlert color="danger" className="mt-4">
                  <p className="mb-2">Your new password needs to:</p>
                  <p className="mb-0">- Be at least 8 characters long</p>
                  <p className="mb-0">
                    - Include both lower and upper case characters
                  </p>
                  <p className="mb-0">- Include at least one number</p>
                </MDBAlert>
              )}
              {!errors.password && errors.passwordConfirmation && (
                <MDBAlert color="danger" className="mt-4">
                  <p className="mb-0">
                    <span className="text-danger font-weight-bold">
                      {oopsText}
                    </span>{" "}
                    {passwordsDontMatchMessageText}
                  </p>
                </MDBAlert>
              )}
              {error && (
                <MDBAlert color="danger" className="mt-4">
                  <p className="mb-0">
                    <span className="text-danger font-weight-bold">
                      {oopsText}
                    </span>{" "}
                    {changePasswordErrorMessage}
                  </p>
                </MDBAlert>
              )}
              <div className="mt-4">
                <ProgressButton
                  className="btn-md font-weight-semi-bold text-capitalize"
                  block={true}
                  color="orange"
                  loading={loading}
                  text={resetPasswordText}
                />
              </div>
            </form>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </MDBView>
  );
};

export default ChangePasswordPage;

import React, { createContext, useContext, useEffect, useState } from "react";
import { isRight } from "../types/Either";
import di from "../../di/di";
import { ContactInformation } from "../../domain/entity/ContactInformation";

const contactInformationContext = createContext({} as any);

export function ProvideContactInformation({ children }) {
  const contactInformation = useProvideContactInformation();
  return (
    <contactInformationContext.Provider value={contactInformation}>
      {" "}
      {children}{" "}
    </contactInformationContext.Provider>
  );
}

export const useContactInformation = () =>
  useContext(contactInformationContext);

function useProvideContactInformation() {
  const [contactInformation, setContactInformation] = useState(
    null as ContactInformation | null
  );
  const [gettingContactInformation, setGettingContactInformation] = useState(
    false
  );

  const getContactInformation = () => {
    setGettingContactInformation(true);

    return di.getContactInformation.execute().then((result) => {
      if (isRight(result)) {
        setContactInformation(result.value);
      }
      setGettingContactInformation(false);
      return result;
    });
  };

  useEffect(() => {
    getContactInformation();
  }, []);

  return {
    contactInformation,
    gettingContactInformation,
    getContactInformation,
  };
}

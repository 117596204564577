import { useAuth } from "core/hooks/useAuth";
import { MDBBtn, MDBCol, MDBCollapse, MDBRow } from "mdbreact";
import React, { FunctionComponent, useState } from "react";

const AflacDisclaimer: FunctionComponent = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { application } = useAuth();

  return application?.isDTC || application?.isISC ? (
    <div className="aflac-disclaimer" id="aflac-disclaimer">
      <MDBBtn
        block
        className="btn-white p-1 z-depth-0 text-align-left text-capitalize text-left rounded-0 mt-3 btn-flat"
        onClick={() => setIsOpen(!isOpen)}
      >
        <MDBRow className="align-items-center" style={{ color: "#3063BB" }}>
          <MDBCol size="auto">
            Disclaimer
            <i
              className={`pl-3 fa ${
                isOpen ? "fa-angle-up" : "fa-angle-down"
              } rotate-icon text-primary`}
            />
          </MDBCol>
        </MDBRow>
      </MDBBtn>
      <MDBCollapse
        isOpen={isOpen}
        className="p-1 small"
        style={{ color: "#303952" }}
      >
        <div className="mb-3" color="elegant-color">
          <span className="d-block">Accident</span>
          <span className="d-block">
            T37000 (Not available in New York and Virginia )
          </span>
          <span className="d-block">
            In Arkansas, Policy T37000ARR. In Idaho, Policies T37100ID,
            T37200ID, & T37300ID. In Oregon, Policy T37000OR. In Oklahoma,
            Policy T37000OK. In Pennsylvania, Policies T37000PA, T37300PA, &
            T37500PA. In Texas, Policy T37000TX.
          </span>
        </div>
        <div className="mb-3">
          <span className="d-block">Cancer</span>
          <span className="d-block">
            T70000 (Not available in New York and Virginia)
          </span>
          <span className="d-block">
            In Arkansas, Policy T70000ARR. In Idaho, Policy T70000ID. In
            Oklahoma, Policy T70000OK. In Oregon, Policy T70000OR. In
            Pennsylvania, Policy T70000PA. In Texas, Policy T70000TX.{" "}
          </span>
        </div>

        <div className="mb-3">
          <span className="d-block">Critical Illness</span>
          <span className="d-block">
            T71000 (Not available in Idaho, New York and Virginia)
          </span>
          <span className="d-block">
            In Arkansas, Policy T71100ARR. In Oklahoma, Policy T71000OK. In
            Oregon, Policy T71000OR. In Pennsylvania, Policies T71100PA -
            T71400PA. In Texas, Policy T71000TXR.{" "}
          </span>
        </div>

        <p>
          This is a brief product overview only. Coverage may not be available
          in all states. cost. Policies and riders may also contain a waiting
          period. Refer to the exact policies and riders for benefit details,
          definitions, limitations and exclusions. For availability and costs,
          please contact your local Aflac agent/producer.
        </p>

        <p>Coverage is underwritten by Tier One Insurance Company.</p>

        <p>
          Aflac family of insurers include Aflac, Aflac New York, Continental
          American Insurance Company, and Tier One Insurance Company.
        </p>

        <p>Aflac | WWHQ | 1932 Wynnton Road | Columbus, GA 31999.</p>

        <div className="d-flex justify-content-between w-50 w-md-25">
          <span>Z2200114</span>
          <span>EXP 3/23</span>
        </div>
      </MDBCollapse>
    </div>
  ) : (
    <></>
  );
};

export default AflacDisclaimer;

import {
  MDBCol,
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBRow,
} from "mdbreact";
import { planText } from "../../core/constants/strings";
import Skeleton from "react-loading-skeleton";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../core/hooks/useAuth";
import IdCardDownloadButton from "./IdCardDownloadButton";

const IdCardDropdown = () => {
  const auth = useAuth();
  const [plan, setPlan] = useState(null as any);
  const [activePlans, setActivePlans] = useState([] as any[]);

  useEffect(() => {
    // If there are any plans at all...
    if (auth.application?.plans?.length > 0) {
      // Pull a subset of all plans that only includes active plans
      const onlyActive = auth.application.plans.filter((plan) => plan.active);
      // If there is a plan with productType `MEDICAL`, sort it to the front of the list of plans
      const medicalFirst = onlyActive.sort((a) => {
        if (a.productType === "MEDICAL") return -1;
        return 0;
      });

      // Set the active plans as the sorted array of plans
      setActivePlans(medicalFirst);
    }
  }, [auth.application]);

  useEffect(() => {
    if (activePlans.length > 0) {
      // If there are any active plans, set the selected plan as the first one in the list
      // Because of the above effect, this will always be the `MEDICAL` plan if one exists
      // If there is no medical plan, a `DENTAL`, `VISION`, or `LATITUDE` plan may be the first plan
      setPlan(activePlans[0]);
    }
  }, [activePlans]);

  return (
    <>
      <MDBRow>
        <MDBCol>
          {!auth.gettingApplication ? (
            (activePlans.length || 0) > 1 && (
              <MDBDropdown className="mb-3">
                <MDBDropdownToggle
                  caret
                  color="white"
                  className="btn-block btn-outline-light-grey d-flex align-items-center text-capitalize"
                >
                  <span className="font-weight-bold black-text mr-auto text-capitalize">
                    {planText.replace(
                      "{plan}",
                      plan?.productType?.toLowerCase()
                    )}
                  </span>
                </MDBDropdownToggle>
                <MDBDropdownMenu
                  color="primary"
                  className="dropdown-custom w-100"
                >
                  {activePlans?.map((it) => (
                    <MDBDropdownItem
                      key={it.id}
                      onClick={() => setPlan(it)}
                      className={`text-capitalize${
                        it.id === plan?.id ? " active" : ""
                      }`}
                    >
                      {planText.replace("{plan}", it.productType.toLowerCase())}
                    </MDBDropdownItem>
                  )) || <div />}
                </MDBDropdownMenu>
              </MDBDropdown>
            )
          ) : (
            <Skeleton height="2.85rem" className="mb-3" />
          )}
        </MDBCol>
      </MDBRow>
      <IdCardDownloadButton plan={plan} />
    </>
  );
};

export default IdCardDropdown;

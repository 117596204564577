import { MDBCol, MDBRow } from "mdbreact";
import familyImage from "../../assets/family.png";
import coupleImage from "../../assets/couple.png";
import singleImage from "../../assets/single.png";
import Skeleton from "react-loading-skeleton";
import React from "react";
import { useAuth } from "../../core/hooks/useAuth";
import MembersDropdown from "./MembersDropdown";

const MembersInformation = () => {
  const auth = useAuth();

  return (
    <MDBRow className="justify-content-center align-items-center">
      <MDBCol
        size="2"
        className="pr-0 pr-md-3 pr-lg-0 col-md-12 col-lg-auto col-xl-2 mb-0 mb-md-3 mb-xl-0"
      >
        {!auth.gettingApplication ? (
          <img
            src={
              auth?.application?.membersType === "family"
                ? familyImage
                : auth?.application?.membersType === "couple"
                ? coupleImage
                : singleImage
            }
            alt={auth?.application?.membersType}
            className="avatar mx-auto"
          />
        ) : (
          <Skeleton
            circle
            height="2.85rem"
            width="2.85rem"
            className="mx-auto"
          />
        )}
      </MDBCol>
      <MembersDropdown />
    </MDBRow>
  );
};

export default MembersInformation;

import React, { createContext, useContext, useEffect, useState } from "react";
import { mobileAppBannerUrl } from "../constants/endpoints";

const pivotMobileBannerContext = createContext(
  {} as { banner: HTMLElement | null }
);

// Provider component that wraps your app and makes auth object available to any child component that calls useAuth().
export function ProvidePivotMobileBanner({ children }) {
  const value = useProvidePivotMobileBanner();
  return (
    <pivotMobileBannerContext.Provider value={value}>
      {" "}
      {children}{" "}
    </pivotMobileBannerContext.Provider>
  );
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const usePivotMobileBanner = () => useContext(pivotMobileBannerContext);

function useProvidePivotMobileBanner() {
  const [banner, setBanner] = useState<HTMLElement | null>(null);

  useEffect(() => {
    const script = document.createElement("script");

    script.src = mobileAppBannerUrl;
    script.id = "hcPivotMobileAppBanner";
    script.async = true;

    script.onload = () => {
      window["hcPivotMobileAppBanner"].push({
        layoutName: "fullWidth",
        utm: {
          utm_source: "pivothealth",
          utm_medium: "member_portal",
          utm_campaign: "member_portal",
          utm_term: "app_download",
          utm_content: "",
        },
        placement: "Member%20Portal",
        containerId: "pivot-mobile-banner",
      });
      const mobileBanner: HTMLElement =
        document.getElementById("pivot-mobile-banner") ||
        document.createElement("div");
      mobileBanner.style.display = "none";
      setBanner(mobileBanner);
    };

    document.body.appendChild(script);
  }, []);

  return { banner };
}

import { Member } from "../../domain/entity/Member";

export class MemberModel extends Member {
  static fromJson = (json) =>
    new MemberModel(
      json?.id,
      json?.firstName,
      json?.lastName,
      json?.dob,
      json?.gender,
      json?.relationship
    );
}

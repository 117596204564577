import { useEffect, useState } from "react";
import { isLeft, isRight } from "../types/Either";
import di from "../../di/di";
import { Document } from "../../domain/entity/Document";
import { useAuth } from "./useAuth";

export function useDocument() {
  const auth = useAuth();
  const [documents, setDocuments] = useState(null as Document[] | null);
  const [documentsPending, setDocumentsPending] = useState(false);
  const [gettingDocuments, setGettingDocuments] = useState(false);

  const getDocuments = () => {
    setGettingDocuments(true);

    return di.getDocuments.execute().then((result) => {
      if (isRight(result)) {
        setDocuments(result.value);
      }
      if (isLeft(result) && result.value["errorCode"] === 404) {
        setDocumentsPending(true);
      }
      setGettingDocuments(false);
      return result;
    });
  };

  useEffect(() => {
    if (auth.user && !gettingDocuments) {
      getDocuments();
    }
  }, [auth.user, auth.gettingApplication]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    documents,
    getDocuments,
    gettingDocuments,
    documentsPending,
  };
}

import { loginUrl, memberUrl } from "../../core/constants/endpoints";
import { UserModel } from "../model/UserModel";
import HttpClient from "../../core/helpers/HttpClient";

export abstract class UserRemoteDataSource {
  abstract login(email: string, password: string): Promise<string>;

  abstract logout(): void;

  abstract getUserByToken(token: string): Promise<UserModel>;
}

export class UserRemoteDataSourceImpl extends UserRemoteDataSource {
  httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    super();
    this.httpClient = httpClient;
  }

  login = (email: string, password: string) =>
    this.httpClient.request(loginUrl, {
      data: { email, password },
      factory: (_, headers) => {
        this.httpClient.authenticateRequests(headers?.authorization);
        return headers?.authorization;
      },
    });

  logout = () => this.httpClient.unauthenticateRequests();

  getUserByToken = (token: string) =>
    this.httpClient.request(memberUrl, {
      headers: { Authorization: `Bearer ${token}` },
      factory: (data) => {
        this.httpClient.authenticateRequests(token);
        return UserModel.fromJson(data);
      },
    });
}

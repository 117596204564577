import { Either } from "../../core/types/Either";
import { User } from "../entity/User";

export abstract class UserRepository {
  abstract login(
    email: string,
    password: string
  ): Promise<Either<Error, string>>;

  abstract getUser(): Promise<Either<Error, User>>;

  abstract getUserByToken(token: string): Promise<Either<Error, User>>;

  abstract logout(): Promise<Either<Error, void>>;
}

import { Document } from "../../domain/entity/Document";

export class DocumentModel extends Document {
  static fromJson = (json) =>
    new Document(
      json?.id,
      json?.planId,
      json?.productType,
      json?.policyId,
      json?.documentName,
      json?.isLegacyApp,
      json?.expirationDate,
      json?.effectiveDate,
      json?.status,
      json?.documentType,
      json?.daysPriorEffectiveDate
    );
}

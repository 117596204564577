import { MDBBtn, MDBCard, MDBCardBody, MDBCol, MDBRow } from "mdbreact";
import {
  errorMessageText,
  tryAgainText,
  unexpectedErrorText,
} from "../../core/constants/strings";
import React, { FunctionComponent } from "react";
import { ReactComponent as ErrorIcon } from "../../assets/error.svg";

interface ErrorComponentProps {
  onTryAgain?: (e?: any) => void;
}

const ErrorComponent: FunctionComponent<ErrorComponentProps> = (props) => {
  return (
    <MDBCard className="mb-3">
      <MDBCardBody className="py-3 py-md-5">
        <MDBRow className="mb-3">
          <MDBCol className="text-center">
            <ErrorIcon />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol>
            <h2 className="text-primary mb-1 h2-responsive text-center">
              {unexpectedErrorText}
            </h2>
            <p className="text-center">{errorMessageText}</p>
          </MDBCol>
        </MDBRow>
        {props.onTryAgain && (
          <MDBRow className="align-items-center">
            <MDBCol className="text-center">
              <MDBBtn
                color="orange"
                className="btn-md m-0 font-weight-semi-bold text-capitalize px-5"
                tag="a"
                onClick={props.onTryAgain}
              >
                {tryAgainText}
              </MDBBtn>
            </MDBCol>
          </MDBRow>
        )}
      </MDBCardBody>
    </MDBCard>
  );
};

export default ErrorComponent;

export class ServerError extends Error {
  readonly message: string;
  readonly errorCode?: number;

  constructor(message: string, errorCode?: number) {
    super(message);
    this.message = message;
    this.errorCode = errorCode;
  }
}

export class SessionExpiredError extends Error {
  constructor() {
    super("Session expired");
  }
}

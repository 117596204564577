import { Either } from "../../core/types/Either";
import { Document } from "../entity/Document";

export abstract class DocumentRepository {
  abstract getDocuments(): Promise<Either<Error, Document[]>>;

  abstract getDocument(
    id?: number,
    appId?: string,
    isLegacyApp?: boolean
  ): Promise<Either<Error, Blob>>;
}

import { User } from "../../domain/entity/User";

export class UserModel extends User {
  static fromJson = (json) =>
    new UserModel(
      json?.id,
      json?.email,
      json?.firstName,
      json?.middleInitial,
      json?.lastName,
      json?.phone,
      json?.phoneAlternate,
      json?.active,
      json?.address,
      json?.city,
      json?.state,
      json?.zip,
      json?.passwordResetFlag,
      json?.lastLoginDatetime,
      json?.newUser,
      json?.loggedInFromAdmin
    );
}

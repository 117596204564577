import { MDBCard, MDBCardBody, MDBCol } from "mdbreact";
import React, { FunctionComponent } from "react";
import pdfImage from "../../assets/pdf.svg";
import { useGetDocument } from "../../core/hooks/useGetDocument";
import { downloadText } from "../../core/constants/strings";
import { isLeft } from "../../core/types/Either";

interface DocumentItemProps {
  id?: number;
  appId?: string;
  isLegacApp?: boolean;
  name?: string;
  productType?: string;
  onDownloadError?: (e?: any) => void;
}

const DocumentItem: FunctionComponent<DocumentItemProps> = (props) => {
  const getDocument = useGetDocument();

  return (
    <MDBCol size="6" sm="3" md="3" lg="3" xl="3" className="mb-3 mb-md-5">
      <MDBCard color="bg-alabaster z-depth-0">
        <MDBCardBody className="text-center">
          <img
            className="img-fluid d-inline-block w-50 mb-2"
            src={pdfImage}
            alt=""
          />
          <p className="mb-2">{props.name}</p>
          {!getDocument.getting ? (
            <button
              className="text-link orange-text text-decoration-underline font-weight-semi-bold"
              id={`${props.productType}_download`}
              onClick={() =>
                getDocument
                  .get(props.id, props.appId, props.name, props.isLegacApp)
                  .then((result) => {
                    if (isLeft(result)) {
                      props.onDownloadError?.();
                    }
                  })
              }
            >
              {downloadText}
            </button>
          ) : (
            <div
              className="spinner-border font-weight-normal spinner-border-sm align-middle orange-text"
              role="status"
            >
              <span className="sr-only">Downloading...</span>
            </div>
          )}
        </MDBCardBody>
      </MDBCard>
    </MDBCol>
  );
};

DocumentItem.defaultProps = {};

export default DocumentItem;

import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "../core/Routes";
import "./App.scss";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { ProvideAuth } from "../core/hooks/useAuth";
import { ProvidePivotMobileBanner } from "../core/hooks/usePivotMobileBanner";
import useGTM from "@elgorditosalsero/react-gtm-hook";
import { googleTagManagerCode } from "../core/constants/strings";
import { ProvideContactInformation } from "../core/hooks/useContactInformation";

function App() {
  const { init, UseGTMHookProvider, sendDataToGTM } = useGTM();

  useEffect(() => init({ id: googleTagManagerCode }), []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    window.addEventListener("load", alertUser);
    return () => {
      window.removeEventListener("load", alertUser);
    };
  }, []);

  const alertUser = (e) => {
    sendDataToGTM({ event: "appLoaded", value: true });
  };

  return (
    <ProvidePivotMobileBanner>
      <UseGTMHookProvider>
        <ProvideAuth>
          <ProvideContactInformation>
            <Router>
              <Header />
              <Routes />
              <Footer />
            </Router>
          </ProvideContactInformation>
        </ProvideAuth>
      </UseGTMHookProvider>
    </ProvidePivotMobileBanner>
  );
}

export default App;

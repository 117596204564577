import { UserRepositoryImpl } from "../data/repository/UserRepositoryImpl";
import { UserRemoteDataSourceImpl } from "../data/dataSource/UserRemoteDataSource";
import Login from "../domain/useCase/Login";
import { UserLocalDataSourceImpl } from "../data/dataSource/UserLocalDataSource";
import ResetPassword from "../domain/useCase/ResetPassword";
import { PasswordRepositoryImpl } from "../data/repository/PasswordRepositoryImpl";
import { PasswordRemoteDataSourceImpl } from "../data/dataSource/PasswordRemoteDataSource";
import GetUser from "../domain/useCase/GetUser";
import Logout from "../domain/useCase/Logout";
import { JwtHelper } from "../core/helpers/JwtHelper";
import { ApplicationRemoteDataSourceImpl } from "../data/dataSource/ApplicationRemoteDataSource";
import { IdCardRepositoryImpl } from "../data/repository/IdCardRepositoryImpl";
import GetApplication from "../domain/useCase/GetApplication";
import { IdCardRemoteDataSourceImpl } from "../data/dataSource/IdCardRemoteDataSource";
import GetIdCard from "../domain/useCase/GetIdCard";
import { ApplicationRepositoryImpl } from "../data/repository/ApplicationRepositoryImpl";
import { DocumentRepositoryImpl } from "../data/repository/DocumentRepositoryImpl";
import { DocumentRemoteDataSourceImpl } from "../data/dataSource/DocumentRemoteDataSource";
import GetDocuments from "../domain/useCase/GetDocuments";
import GetDocument from "../domain/useCase/GetDocument";
import GetUserByToken from "domain/useCase/GetUserByToken";
import ChangePassword from "../domain/useCase/ChangePassword";
import UpdatePassword from "../domain/useCase/UpdatePassword";
import { ContactInformationRemoteDataSourceImpl } from "../data/dataSource/ContactInformationRemoteDataSource";
import GetContactInformation from "../domain/useCase/GetContactInformation";
import { ContactInformationRepositoryImpl } from "../data/repository/ContactInformationRepositoryImpl";
import GetIbaLink from "../domain/useCase/GetIbaLink";
import { CookieHelper } from "../core/helpers/CookieHelper";
import HttpClient from "../core/helpers/HttpClient";
import { QuoteRepositoryImpl } from "../data/repository/QuoteRepositoryImpl";
import { QuoteRemoteDataSourceImpl } from "../data/dataSource/QuoteRemoteDataSource";
import GetQuote from "../domain/useCase/GetQuote";
import { AttestationRepositoryImpl } from "data/repository/AttestationRepositoryImpl";
import { AttestationRemoteDataSourceImpl } from "data/dataSource/AttestationRemoteDataSource";
import GetAttestation from "domain/useCase/GetAttestation";
import SubmitAttestation from "domain/useCase/SubmitAttestation";

// Core
const cookieHelper = new CookieHelper();
const httpClient = new HttpClient();
const jwtHelper = new JwtHelper();

// Contact Information
const contactInformationRepository = new ContactInformationRepositoryImpl(
  new ContactInformationRemoteDataSourceImpl(httpClient)
);
const getContactInformation = new GetContactInformation(
  contactInformationRepository
);

// User
const userRepository = new UserRepositoryImpl(
  new UserRemoteDataSourceImpl(httpClient),
  new UserLocalDataSourceImpl(cookieHelper),
  jwtHelper
);
const login = new Login(userRepository);
const getUser = new GetUser(userRepository);
const getUserByToken = new GetUserByToken(userRepository);
const logout = new Logout(userRepository);

// Password
const passwordRepository = new PasswordRepositoryImpl(
  new PasswordRemoteDataSourceImpl(httpClient)
);
const resetPassword = new ResetPassword(passwordRepository);
const changePassword = new ChangePassword(passwordRepository);
const updatePassword = new UpdatePassword(passwordRepository);

// Application
const applicationRepository = new ApplicationRepositoryImpl(
  new ApplicationRemoteDataSourceImpl(httpClient)
);
const getApplication = new GetApplication(applicationRepository);
const getIbaLink = new GetIbaLink(applicationRepository);

// Quote
const quoteRepository = new QuoteRepositoryImpl(
  new QuoteRemoteDataSourceImpl(httpClient)
);
const getQuote = new GetQuote(quoteRepository);

// ID Card
const idCardRepository = new IdCardRepositoryImpl(
  new IdCardRemoteDataSourceImpl(httpClient)
);
const getIdCard = new GetIdCard(idCardRepository);

// DocumentItem
const documentRepository = new DocumentRepositoryImpl(
  new DocumentRemoteDataSourceImpl(httpClient)
);
const getDocuments = new GetDocuments(documentRepository);
const getDocument = new GetDocument(documentRepository);

// Attestation
const attestationRepository = new AttestationRepositoryImpl(
  new AttestationRemoteDataSourceImpl(httpClient)
);
const getAttestation = new GetAttestation(attestationRepository);
const submitAttestation = new SubmitAttestation(attestationRepository);

export default {
  getContactInformation: getContactInformation,
  login: login,
  getUser: getUser,
  getUserByToken: getUserByToken,
  logout: logout,
  resetPassword: resetPassword,
  changePassword: changePassword,
  updatePassword: updatePassword,
  getApplication: getApplication,
  getQuote: getQuote,
  getIbaLink: getIbaLink,
  getIdCard: getIdCard,
  getDocuments: getDocuments,
  getDocument: getDocument,
  getAttestation: getAttestation,
  submitAttestation: submitAttestation,
};

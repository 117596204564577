import { useState } from "react";
import { isRight } from "../types/Either";
import di from "../../di/di";
import { GetIdCardParams } from "../../domain/useCase/GetIdCard";
import downloadBlob from "../functions/downloadBlob";

export function useDownloadIdCard() {
  const [isDownloading, setIsDownloading] = useState(false);

  const download = (
    appId?: number,
    productType?: string,
    isLegacyApp?: boolean
  ) => {
    setIsDownloading(true);

    return di.getIdCard
      .execute(new GetIdCardParams(appId, productType, isLegacyApp))
      .then((result) => {
        if (isRight(result)) {
          downloadBlob(result.value, `${appId}_${productType}_ID_CARD.pdf`);
        }

        setIsDownloading(false);

        return result;
      });
  };

  return {
    download,
    isDownloading,
  };
}

import { mobileUrl } from "core/constants/endpoints";
import HttpClient from "core/helpers/HttpClient";
import { AttestationModel } from "data/model/AttestationModel";

/**
 * A remote data source for pulling and submitting attestations
 * @param getAttestation A function for loading the attestation from the back-end
 * @param submitAttestation A function for submitting a new attestation to the back-end
 */
export abstract class AttestationRemoteDataSource {
  abstract getAttestation(userId: number): Promise<AttestationModel>;
  abstract submitAttestation(userId: number): Promise<AttestationModel>;
}

/**
 * An implementation of AttestationRemoteDataSource
 */
export class AttestationRemoteDataSourceImpl extends AttestationRemoteDataSource {
  httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    super();
    this.httpClient = httpClient;
  }

  getAttestation = (userId: number) =>
    this.httpClient.request(
      `${mobileUrl}/mobile/member/${userId}/attestation`,
      {
        factory: (data) => AttestationModel.fromJson(data),
      }
    );

  submitAttestation = (userId: number) =>
    this.httpClient.request(`${mobileUrl}/mobile/member/attestation`, {
      method: "POST",
      data: {
        userId: userId,
      },
      factory: (data) => AttestationModel.fromJson(data),
    });
}

import UseCase from "core/useCase/UseCase";
import { Attestation } from "domain/entity/Attestation";
import { AttestationRepository } from "domain/repository/AttestationRepository";

/**
 * A use case for submitting attestations that can be called as part of di
 */
export default class SubmitAttestation extends UseCase<
  Attestation,
  SubmitAttestationParams
> {
  repository: AttestationRepository;

  constructor(repository: AttestationRepository) {
    super();
    this.repository = repository;
  }

  execute = (params: SubmitAttestationParams) =>
    this.repository.submitAttestation(params.userId);
}

/**
 * Parameters for submitting an attestation
 * @param userId The ID of the user for which an attestation will be created
 */
export class SubmitAttestationParams {
  userId?: number;

  constructor(userId?: number) {
    this.userId = userId;
  }
}

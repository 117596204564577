import { MDBCol, MDBRow } from "mdbreact";
import {
  activeText,
  daysAgoText,
  daysText,
  expiredText,
  expiresInText,
  inactiveText,
  medicalPlanText,
  noneText,
} from "../../core/constants/strings";
import Skeleton from "react-loading-skeleton";
import React from "react";
import { useAuth } from "../../core/hooks/useAuth";

const PlanInformation = () => {
  const auth = useAuth();
  const isApplicationActive = auth.application?.active;
  const expirationDays = auth.application?.expirationDays;

  const stmInformation = (
    <p className="text-right text-md-left text-xl-right silver-text">
      <span
        id="medical-plan-status"
        className={`${
          isApplicationActive
            ? expirationDays >= 30
              ? "text-success"
              : expirationDays >= 10
              ? "text-warning"
              : "text-danger"
            : "text-danger"
        } font-weight-bold`}
      >
        {isApplicationActive ? activeText + "**" : inactiveText}
      </span>
      , {isApplicationActive ? expiresInText : expiredText}
      <span id="medical-plan-duration-remaining">
        <strong className="font-weight-bold">
          {" "}
          {isApplicationActive
            ? daysText.replace("{days}", expirationDays?.toString() || "N/A")
            : daysAgoText.replace(
                "{daysAgo}",
                expirationDays?.toString() || "N/A"
              )}
        </strong>
      </span>
    </p>
  );

  const fbmInfomation = (
    <p className="text-right text-md-left text-xl-right silver-text">
      <span id="medical-plan-status" className="text-success font-weight-bold">
        {activeText + "**"}
      </span>
    </p>
  );

  const getInformationText =
    auth.application?.type === "FBM" ? (
      fbmInfomation
    ) : auth.application?.productType === "MEDICAL" ? (
      stmInformation
    ) : (
      <p className="text-right text-md-left text-xl-right silver-text">
        {noneText}
      </p>
    );

  return (
    <>
      <MDBRow>
        <MDBCol size="auto" className="col-md-12 col-xl-auto">
          <p>{medicalPlanText}</p>
        </MDBCol>
        <MDBCol>
          {!auth.gettingApplication ? getInformationText : <Skeleton />}
        </MDBCol>
      </MDBRow>
    </>
  );
};

export default PlanInformation;

import React, { useEffect, useState } from "react";
import { MDBAlert, MDBCol, MDBContainer, MDBRow, MDBView } from "mdbreact";
import loginImage from "../../assets/login.svg";
import { Link } from "react-router-dom";
import {
  changePasswordSuccessMessage,
  emailText,
  fieldRequiredText,
  forgotPasswordText,
  goHereText,
  greatText,
  incorrectEmailText,
  loginAsAgentText,
  loginErrorText,
  loginText,
  memberPortalText,
  oopsText,
  passwordText,
  rememberMeText,
  welcomeToText,
} from "../../core/constants/strings";
import { isRight } from "../../core/types/Either";
import { useRouter } from "../../core/hooks/useRouter";
import { useAuth } from "../../core/hooks/useAuth";
import ProgressButton from "../components/ProgressButton";
import useToggle from "react-use-toggle";
import { useForm } from "react-hook-form";
import { agentLoginUrl } from "../../core/constants/endpoints";
import useGTM from "@elgorditosalsero/react-gtm-hook";
import executeWhenFinishToRender from "../../core/functions/executeWhenFinishToRender";

const LoginPage = (props) => {
  const router = useRouter();
  const auth = useAuth();
  const { sendDataToGTM } = useGTM();
  const [rememberMe, toggleRememberMe] = useToggle(
    (localStorage.getItem("rememberMe")?.length || 0) > 0
  );
  const [submitted, setSubmitted] = useState(false);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [hasError, setHasError] = useState(false);
  const passwordChanged = props?.location?.state?.passwordChanged || false;

  const { handleSubmit, register, errors, watch } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: true,
    defaultValues: {
      email: localStorage.getItem("rememberMe") || "",
      password: "",
    },
  });

  const onSubmit = (values) => {
    setSubmitted(true);
    setIsLoggingIn(true);
    auth
      .login(values.email, values.password)
      .then((result) => {
        if (isRight(result)) {
          setHasError(false);
          localStorage.setItem("rememberMe", rememberMe ? values.email : "");
          sendDataToGTM({ event: "login", value: "success" });
          router.replace("/");
        } else {
          setHasError(true);
          sendDataToGTM({ event: "login", value: "error" });
        }
      })
      .finally(() => setIsLoggingIn(false));
  };

  useEffect(() => executeWhenFinishToRender(), []);

  return (
    <MDBView className="full py-3 py-md-5 ">
      <MDBContainer>
        <MDBRow className="justify-content-center">
          <MDBCol className="d-none d-md-block">
            <img className="img-fluid w-100" src={loginImage} alt="" />
          </MDBCol>
          <MDBCol size="10" md="5" className="px-3 px-md-0">
            <h3 className="h3-responsive mb-0 font-weight-normal">
              {welcomeToText}
            </h3>
            <h1 className="font-weight-semi-bold text-primary display-4 mb-3 tk-bree-serif">
              {memberPortalText}
            </h1>
            <form
              className={`needs-validation ${submitted ? "was-validated" : ""}`}
              onSubmit={handleSubmit(onSubmit)}
            >
              <label
                htmlFor="email"
                className="font-weight-semi-bold text-uppercase"
              >
                {emailText}
              </label>
              <input
                type="email"
                name="email"
                id="email"
                placeholder={emailText}
                className={`form-control${errors.email ? " is-invalid" : ""}`}
                ref={register({
                  required: fieldRequiredText,
                  pattern: {
                    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
                    message: incorrectEmailText,
                  },
                })}
              />
              <div className="invalid-feedback">{errors.email?.message}</div>
              <label
                htmlFor="password"
                className="font-weight-semi-bold text-uppercase mt-4"
              >
                {passwordText}
              </label>
              <input
                type="password"
                name="password"
                id="password"
                placeholder={passwordText}
                className={`form-control${
                  errors.password ? " is-invalid" : ""
                }`}
                ref={register({
                  required: fieldRequiredText,
                })}
              />
              <div className="invalid-feedback">{errors.password?.message}</div>
              {hasError && (
                <MDBAlert color="danger" className="mt-4">
                  <p className="mb-0">
                    <span className="text-danger font-weight-bold">
                      {oopsText}
                    </span>{" "}
                    {loginErrorText}
                  </p>
                </MDBAlert>
              )}
              {passwordChanged && (
                <MDBAlert color="success" className="mt-4">
                  <p className="mb-0">
                    <span className="text-success font-weight-bold">
                      {greatText}
                    </span>{" "}
                    {changePasswordSuccessMessage}
                  </p>
                </MDBAlert>
              )}
              <div className="custom-control custom-checkbox mt-4">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  checked={rememberMe}
                  onChange={toggleRememberMe}
                  id="rememberme"
                />
                <label className="custom-control-label" htmlFor="rememberme">
                  {rememberMeText}
                </label>
              </div>
              <div className="mt-4">
                <ProgressButton
                  className="btn-md font-weight-semi-bold text-capitalize"
                  block={true}
                  color="orange"
                  loading={isLoggingIn}
                  text={loginText}
                />
              </div>
              <p className="mt-4 mb-0">
                <Link
                  to={{
                    pathname: "/forgot-password",
                    state: {
                      email: watch("email"),
                    },
                  }}
                  className="font-weight-normal text-decoration-underline"
                  id="forgot-password"
                >
                  <u>{forgotPasswordText}</u>
                </Link>
              </p>
              <hr className="my-4" />
              <p className="mb-0">
                {loginAsAgentText}{" "}
                <a href={agentLoginUrl} className="text-link">
                  <u>{goHereText}</u>
                </a>
              </p>
            </form>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </MDBView>
  );
};

export default LoginPage;

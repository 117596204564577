import React, { createContext, useContext, useEffect, useState } from "react";
import di from "../../di/di";
import { LoginParams } from "../../domain/useCase/Login";
import { ResetPasswordParams } from "../../domain/useCase/ResetPassword";
import { isLeft, isRight } from "../types/Either";
import { User } from "../../domain/entity/User";
import { Application } from "../../domain/entity/Application";
import { GetUserByTokenParams } from "domain/useCase/GetUserByToken";
import { ChangePasswordParams } from "../../domain/useCase/ChangePassword";
import { UpdatePasswordParams } from "../../domain/useCase/UpdatePassword";
import { SessionExpiredError } from "../error/error";
import { Quote } from "../../domain/entity/Quote";
import useGTM from "@elgorditosalsero/react-gtm-hook";

const authContext = createContext({} as any);

// Provider component that wraps your app and makes auth object available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}> {children} </authContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => useContext(authContext);

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const [user, setUser] = useState(null as User | null);
  const [application, setApplication] = useState(null as Application | null);
  const [gettingApplication, setGettingApplication] = useState(true);
  const [errorGettingApplication, setErrorGettingApplication] = useState(false);
  const [quotes, setQuotes] = useState(null as Array<Quote> | null);
  const [gettingQuote, setGettingQuote] = useState(false);
  const { sendDataToGTM } = useGTM();

  useEffect(() => {
    getUser().then();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getUser = () =>
    di.getUser.execute().then((response) => {
      if (isRight(response)) {
        setUser(response.value);
        sendDataToGTM({ event: "userSetted", userId: response.value.id });
        getApplication();
      }

      if (isLeft(response) && response.value instanceof SessionExpiredError) {
        setUser(null);
        setApplication(null);
      }

      return response;
    });

  const shouldGetQuote = (application: Application): boolean => {
    return (application.isFromEnrollPage &&
      ((!application.dentalPlan?.active && !application.visionPlan?.active) ||
        !application.latitudePlan?.active)) as boolean;
  };

  const getApplication = () => {
    setGettingApplication(true);

    return di.getApplication.execute().then((response) => {
      if (isRight(response)) {
        const application = response.value;
        setErrorGettingApplication(false);
        setApplication(application);

        if (application.type !== "FBM") {
          sendDataToGTM({
            event: "showCovidBanner",
            carrierId: application.carrierId,
            state: application.state,
          });
        }

        sendDataToGTM({
          event: "applicationSetted",
          carrierId: application.carrierId,
        });
        if (shouldGetQuote(application)) {
          getQuotes();
        }
      } else {
        setErrorGettingApplication(true);
      }

      setGettingApplication(false);

      return response;
    });
  };

  const getQuotes = () => {
    setGettingQuote(true);

    return di.getQuote.execute().then((response) => {
      if (isRight(response)) {
        setQuotes(response.value);
      }

      setGettingQuote(false);

      return response;
    });
  };

  const login = (email: string, password: string) =>
    di.login.execute(new LoginParams(email, password)).then((response) => {
      return response;
    });

  const getUserByToken = (token: string) =>
    di.getUserByToken
      .execute(new GetUserByTokenParams(token))
      .then((response) => {
        if (isRight(response)) {
          setUser(response.value);
        }
        return response;
      });

  const logout = () =>
    di.logout.execute().then(() => {
      setUser(null);
      setApplication(null);
      setQuotes(null);
    });

  const resetPassword = (email: string) =>
    di.resetPassword.execute(new ResetPasswordParams(email));

  const changePassword = (token: string, password: string) =>
    di.changePassword.execute(new ChangePasswordParams(token, password));

  const updatePassword = (currentPassword: string, newPassword: string) =>
    di.updatePassword.execute(
      new UpdatePasswordParams(currentPassword, newPassword)
    );

  // Return the user object and auth methods
  return {
    user,
    application,
    errorGettingApplication,
    gettingApplication,
    quotes,
    gettingQuote,
    getUser,
    login,
    logout,
    resetPassword,
    changePassword,
    updatePassword,
    getUserByToken,
    getApplication,
  };
}

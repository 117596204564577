import { useAuth } from "../../core/hooks/useAuth";
import { useRouter } from "../../core/hooks/useRouter";
import React from "react";
import {
  MDBCol,
  MDBIcon,
  MDBListGroup,
  MDBListGroupItem,
  MDBRow,
} from "mdbreact";
import {
  accountSettingsText,
  cancelText,
  logoutMessageText,
  logoutText,
} from "../../core/constants/strings";
import useToggle from "react-use-toggle";
import AlertModal from "./AlertModal";

const SidebarBottomMenu = () => {
  const auth = useAuth();
  const router = useRouter();
  const [isLogoutModalOpen, setLogoutModalOpen] = useToggle(false);

  function openLogoutModal(e) {
    e.preventDefault();
    setLogoutModalOpen();
  }

  function navigateToAccountSettings(e) {
    e.preventDefault();
    router.replace("/account-settings");
  }

  function logout() {
    auth.logout();
    router.replace("/login");
  }

  return (
    <>
      <MDBRow className="d-none d-md-block" id="bottom-menu">
        <MDBCol className="p-0">
          <MDBListGroup className="list-group-flush border-top">
            <MDBListGroupItem
              href="/account-settings"
              className="font-weight-semi-bold"
              onClick={navigateToAccountSettings}
              id="account-settings"
            >
              <MDBIcon icon="cog" className="ml-1 mr-3" />
              {accountSettingsText}
            </MDBListGroupItem>
            <MDBListGroupItem
              href="/login"
              hover
              className="font-weight-semi-bold"
              onClick={openLogoutModal}
              id="logout"
            >
              <MDBIcon icon="sign-out-alt" className="ml-1 mr-3" />
              {logoutText}
            </MDBListGroupItem>
          </MDBListGroup>
        </MDBCol>
      </MDBRow>
      <AlertModal
        isOpen={isLogoutModalOpen}
        title={logoutText}
        body={logoutMessageText}
        positive={logoutText}
        negative={cancelText}
        onPositive={logout}
        onNegative={setLogoutModalOpen}
        onDismiss={setLogoutModalOpen}
      />
    </>
  );
};

export default SidebarBottomMenu;

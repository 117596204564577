//constant
export const pointHealthPhoneNumberText = "855-827-9806";

// Header
export const needHelpText = "Need help?";
export const helpPhoneText = "1-866-566-2707";
export const accountSettingsText = "Account Settings";
export const welcomeBackText = "Welcome Back!";
export const coverageInformationText = "Coverage information";
export const documentsText = "Documents";
export const helpText = "Help";

//Footer
export const copyrightText = "Copyright";
export const pivotHealthText = "Pivot Health";
export const privacyPolicyText = "Privacy Policy";
export const termsOfServiceText = "Terms of Service";
export const contactUsText = "Contact us";

// Login
export const welcomeToText = "Welcome to";
export const memberPortalText = "Pivot Health";
export const emailText = "Email";
export const passwordText = "Password";
export const rememberMeText = "Remember me";
export const loginText = "Login";
export const forgotPasswordText = "Forgot Password?";
export const incorrectEmailText = "Incorrect email";
export const incorrectPasswordText = "Incorrect password";
export const loginErrorText =
  "The email address or password you entered is invalid. Re-enter your information for an existing account & try again!";
export const loginAsAgentText = "Are you looking to login as an agent?";
export const goHereText = "Go Here";

// Reset Password
export const resetMyPasswordText = "Reset my password";
export const resetPasswordText = "Reset Password";
export const resetPasswordErrorText =
  "The email address invalid. Re-enter your information for an existing account & try again!";
export const resetPasswordSuccessText =
  "If the account exists we have sent an email to {email}. Click the link in the email to reset your password";

// Personal Information
export const personalInformationTitleText = "Personal Information";
export const nameLabelText = "Name";
export const phoneLabelText = "Phone Number";
export const emailLabelText = "Email";
export const addressLabelText = "Street Address";
export const stateLabelText = "State";
export const zipLabelText = "ZIP Code";

// Change Password
export const changePasswordText =
  "Please change your password before proceeding further";
export const changePasswordTitleText = "Change Password";
export const changePasswordHintText =
  "New password must be at least 8 characters in length and contain at least one uppercase character, one lowercase character and one numeral";
export const currentPasswordLabelText = "Current Password";
export const currentPasswordText = "Enter current password";
export const newPasswordLabelText = "New Password";
export const newPasswordText = "Enter your new password";
export const newPasswordConfirmationLabelText = "Confirm New password";
export const newPasswordConfirmationText = "Confirm your new password";
export const passwordsDontMatchText = "Your passwords do not match";
export const passwordsDontMatchMessageText =
  "Your passwords do not match. Make sure you enter the same password";
export const changePasswordSuccessMessage =
  "The password was successfully changed, please authenticate with your new password";
export const changePasswordErrorMessage =
  "The link has expired or is invalid, please reset your password again";
export const updatePasswordErrorMessage = "Your current password is invalid";
export const updatePasswordSuccessMessage =
  "Your new password changes are saved";
export const saveChangesText = "Save Changes";

// Sidebar
export const membersText = "Members";
export const medicalPlanText = "Medical Plan";
export const dentalPlanText = "Dental Plan";
export const visionPlanText = "Vision Plan";
export const latitudePlanText = "Latitude Plan";
export const enrollNowText = "Enroll now";
export const eligibilityText = "*Depends on your eligibility";
export const yourCardsText = "Your cards";
export const downloadIdCardText = "Download ID Card";
export const planText = "{plan} plan";
export const idCardExpiredText = "ID Card expired";
export const fbmEffectiveDateDisclaimerText =
  "**Coverage reflects status if all premiums are paid.";
export const stmEffectiveDateDisclaimerText =
  "**Date reflects expiration if all required premiums are paid.";

// Agents
export const agenciesList = ["150000", "150010", "150011", "58568"];
export const salesCenterAgencyId = "58568";
export const agencyPhoneNumber = "18665662707";

// Logout
export const logoutText = "Logout";
export const logoutMessageText = "Do you want to log out?";

// Dashboard
export const appIdText = "Application ID";
export const accessClaimsAndBillingText =
  "Access your claims and billing information from the plan administrator, Insurance Benefit Administrators, by";
export const accessBillingText =
  "Access your billing information from the plan administrator, Insurance Benefit Administrators, by";
export const telemedicineText =
  "Our non-insurance benefits can help you stay healthy and reduce medical expenses overall. In partnership with NaviGo, get access to free telehealth visits, medical equipment discounts, and more!";
export const clickingHereText = "clicking here";
export const clickHereText = "Click here";
export const toLoginMemberPortalText =
  "to log directly into their member portal.";
export const findADoctorText = "Find a Doctor";
export const findADentistText = "Find a Dentist";
export const findAnEyeDoctorText = "Find an Eye Doctor";
export const viewBrochureText = "View Brochure";
export const visionProvidersText = "Vision Providers";
export const addNowText = "Add Now";
export const findADoctorMessage =
  "Visit our medical directory and find a doctor according to your needs.";
export const pointHealthMessage =
  "Your fixed benefit plan policy includes access to Point Health. Get access to patient advocacy services and medical bill negotiation.";
export const shortTermPointHealthMessage =
  "Your STM plan policy includes access to Point Health.";
export const toDownloadAppText = "to download the app";
export const toDownloadAppOrCallText = "to download the app or call ";

// Documents
export const expirationDateText = "Expiration Date";
export const effectiveDateText = "Effective Date";
export const notAppliesText = "N/A";
export const seeAllDocumentsText = "See All Documents";
export const seeLessDocumentsText = "See Less Documents";
export const downloadAllDocumentsText = "Download All Documents as .zip";
export const downloadDocumentText = "Download Document";
export const errorDownloadingText =
  "There was an error downloading the requested document. Please try again later or contact our support team for assistance.";
export const noDocumentsText =
  "There are no documents at this moment, please try again later.";
export const documentsGeneratingText =
  "Your documents are still being generated, please try again later.";
export const fulfillmentDocsNotAvailableText =
  "The documents will be available {days} days prior to the effective date; they will be available on: {date}";

// Help
export const needHelpTitleText = "Need help?";
export const insuranceBenefitAdministratorsText =
  "Insurance Benefit Administrators";
export const customerServiceText =
  "Customer Service for premium billing, benefit, and claim status questions";
export const callText = "Call";
export const supportText = "Support";
export const pointHealthText = "Point Health";
export const pointHealthWidgetSubtitle =
  "Your {medicalPlan} policy includes access to Point Health Patient Advocacy services.";
export const anchorFixedBenefitText = "anchor fixed benefit medical";
export const shortTermText = "short term medical";

// General
export const oopsText = "Oops!";
export const greatText = "Great!";
export const moreText = "(+{amount})";
export const expiredText = "expired";
export const expiresInText = "expires in";
export const daysAgoText = "{daysAgo} days ago";
export const daysText = "{days} days";
export const activeText = "Active";
export const inactiveText = "Inactive";
export const noneText = "None";
export const cancelText = "Cancel";
export const okText = "OK";
export const tryAgainText = "Try again";
export const errorMessageText =
  "Sorry for the inconvenience, we are currently experiencing errors. Please try again later or contact our support team for assistance.";
export const downloadText = "Download";
export const fieldRequiredText = "This field is required";
export const pageNotFoundTitleText = "404 Not Found";
export const pageNotFoundDescriptionText =
  "Oops! The page you are looking for does not exist.";
export const backToHomeText = "Back to Home";
export const unexpectedErrorText = "Oops! Unexpected Error";

// Google Tag Manager
export const googleTagManagerCode = "GTM-WBJ2BN9";

// UTM
export const utmSourceCrossSellDv = "cross_sell_dv";
export const utmSourceCrossSellLatitude = "cross_sell_latitude";
export const utmMediumOrganic = "organic";
export const utmCampaignMemberPortal = "member_portal";

//Quotes
export const planDetails = "Plan Details";
export const plansStartingFrom = "We have plans starting from";
export const latitudeQuoteTitle = "Add Supplemental Coverage To Your Plan";
export const dentalAndVisionQuoteTitle =
  "Add Dental & Vision Coverage to Your Plan";
export const dentalAndVisionEnrollDisclaimerText =
  "In order to enroll for Dental or Dental and Vision coverage, membership in Communicating for America association is required.";
export const dentalAndVisionlearnMoreAboutBenefitsText =
  "Click here to learn more about Communicating for America association benefits.";

//plan widget titles
export const telemedicineTitleText = "Telemedicine";
export const pointHealthTitleText = "Point Health Advocacy Services";
export const conciergeServicesTitleText = "Patient advocacy services";
export const claimsAndBillingText = "Claims and Billing";
export const BillingText = "Billing";

//aditional benefit
export const patientAdvocacyServicesBenefit =
  "<div>" +
  "<b>" +
  "PATIENT ADVOCACY SERVICES" +
  "</b>" +
  "<p>" +
  "Our short-term medical insurance policies come with a professional patient advocate ready to help you navigate what can be a healthcare maze. Included at no additional cost, this service can help you find doctors and get appointments made. Point Health's friendly advocates help you get the care you need while managing your costs." +
  "<p>" +
  "</div>";

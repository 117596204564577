import { AddOn } from "../../domain/entity/AddOn";

export class AddOnModel extends AddOn {
  static fromJson = (json) =>
    new AddOnModel(
      json.id,
      json.type?.toString()?.toLowerCase(),
      json.active,
      json.productType,
      json.benefits,
      json.appId,
      json.packageName,
      json.policyId,
      json.isLegacyApp,
      json.expirationDate
    );
}

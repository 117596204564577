import { contactInformationUrl } from "../../core/constants/endpoints";
import { ContactInformationModel } from "../model/ContactInformationModel";
import HttpClient from "../../core/helpers/HttpClient";

export abstract class ContactInformationRemoteDataSource {
  abstract getContactInformation(): Promise<ContactInformationModel>;
}

export class ContactInformationRemoteDataSourceImpl extends ContactInformationRemoteDataSource {
  httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    super();
    this.httpClient = httpClient;
  }

  getContactInformation = () =>
    this.httpClient.request(contactInformationUrl, {
      factory: (data) => ContactInformationModel.fromJson(data),
    });
}

export class ContactInformation {
  readonly id: number;
  readonly category: string;
  readonly email: string;
  readonly emailName: string;
  readonly phone: string;
  readonly phoneName: string;
  readonly phoneOption: string;

  constructor(
    id: number,
    category: string,
    email: string,
    emailName: string,
    phone: string,
    phoneName: string,
    phoneOption: string
  ) {
    this.id = id;
    this.category = category;
    this.email = email;
    this.emailName = emailName;
    this.phone = phone;
    this.phoneName = phoneName;
    this.phoneOption = phoneOption;
  }
}

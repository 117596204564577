import { Either, Left, Right } from "../../core/types/Either";
import { PasswordRepository } from "../../domain/repository/PasswordRepository";
import { PasswordRemoteDataSource } from "../dataSource/PasswordRemoteDataSource";

export class PasswordRepositoryImpl extends PasswordRepository {
  remoteDataSource: PasswordRemoteDataSource;

  constructor(remoteDataSource: PasswordRemoteDataSource) {
    super();
    this.remoteDataSource = remoteDataSource;
  }

  async reset(email: string): Promise<Either<Error, any>> {
    try {
      return Right(await this.remoteDataSource.reset(email));
    } catch (e) {
      return Left(e);
    }
  }

  async change(token: string, password: string): Promise<Either<Error, any>> {
    try {
      return Right(await this.remoteDataSource.change(token, password));
    } catch (e) {
      return Left(e);
    }
  }

  async update(
    currentPassword: string,
    newPassword: string
  ): Promise<Either<Error, any>> {
    try {
      return Right(
        await this.remoteDataSource.update(currentPassword, newPassword)
      );
    } catch (e) {
      return Left(e);
    }
  }
}

import { MDBListGroupItem } from "mdbreact";
import React from "react";
import { Document } from "domain/entity/Document";
import { useGetDocument } from "core/hooks/useGetDocument";
import styles from "../scss/attestation.module.scss";

/**
 * Interface describing props for AttestationDocumentLink component
 * @param documents The list of documents associated with a user's account (from the getDocuments hook)
 * @param documentTypes The types of document that will be shown in the list
 */
export interface AttestationDocumentLinksProps {
  documents: Document[] | null;
  documentTypes: string[];
}

/**
 * Renders links
 * @param props - see AttestationDocumentLinkProps
 * @returns A list of links that will download the associated documents
 */
const AttestationDocumentLinks = (props: AttestationDocumentLinksProps) => {
  const getDocuments = useGetDocument();

  const downloadDocument = (doc: Document | undefined) => {
    if (doc) {
      getDocuments.get(doc.id, doc.policyId, doc.documentName, doc.isLegacyApp);
    }
  };
  // If there are no documents passed in, render nothing.
  if (!props.documents) return <></>;

  // Once we know we have documents, filter to only show docs from current and upcoming policies
  // After filtering, sort by appId
  const displayDocuments = props.documents
    .filter(
      (document) =>
        (document.status === "CURRENT" || document.status === "UPCOMING") &&
        document.documentType &&
        props.documentTypes.includes(document.documentType)
    )
    .sort((a, b) => {
      if (a.planId && b.planId) {
        const aId = parseInt(a.planId);
        const bId = parseInt(b.planId);
        if (aId < bId) return 1;
        else if (aId === bId) return 0;
        else return -1;
      } else return 0;
    });

  // If there are no current or upcoming docs that match the required doc types, render nothing
  if (displayDocuments.length === 0) return <></>;

  // We won't have reached this point unless we have everything we need, so map over the docs and render them
  return (
    <>
      {displayDocuments.map((doc) => {
        return (
          <MDBListGroupItem
            key={doc.id}
            onClick={() => downloadDocument(doc)}
            className={styles.documentLink}
          >
            {`Policy #${doc.policyId}`} - <b>{doc.documentName}</b>
          </MDBListGroupItem>
        );
      })}
    </>
  );
};

export default AttestationDocumentLinks;

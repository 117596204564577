import { MDBCol, MDBRow } from "mdbreact";
import {
  activeText,
  dentalPlanText,
  latitudePlanText,
  noneText,
  visionPlanText,
} from "../../core/constants/strings";
import Skeleton from "react-loading-skeleton";
import React from "react";
import { useAuth } from "../../core/hooks/useAuth";

const AddOnsInformation = () => {
  const auth = useAuth();
  const isDentalActive =
    (auth.application?.productType === "DENTAL" && auth.application?.active) ||
    auth.application?.dentalPlan?.active;
  const isVisionActive =
    (auth.application?.productType === "VISION" && auth.application?.active) ||
    auth.application?.visionPlan?.active;
  const isLatitudeActive =
    (auth.application?.productType === "LATITUDE" &&
      auth.application?.active) ||
    auth.application?.latitudePlan?.active;

  return (
    <>
      <MDBRow>
        <MDBCol size="auto">
          <p>{dentalPlanText}</p>
        </MDBCol>
        <MDBCol>
          {!auth.gettingApplication ? (
            <p
              id="dental-plan-status"
              className={`text-right ${
                isDentalActive ? "text-success font-weight-bold" : "silver-text"
              }`}
            >
              {isDentalActive ? activeText : noneText}
            </p>
          ) : (
            <Skeleton />
          )}
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol size="auto">
          <p>{visionPlanText}</p>
        </MDBCol>
        <MDBCol>
          {!auth.gettingApplication ? (
            <p
              id="vision-plan-status"
              className={`text-right ${
                isVisionActive ? "text-success font-weight-bold" : "silver-text"
              }`}
            >
              {isVisionActive ? activeText : noneText}
            </p>
          ) : (
            <Skeleton />
          )}
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol size="auto">
          <p>{latitudePlanText}</p>
        </MDBCol>
        <MDBCol>
          {!auth.gettingApplication ? (
            <p
              id="latitud-plan-status"
              className={`text-right ${
                isLatitudeActive
                  ? "text-success font-weight-bold"
                  : "silver-text"
              }`}
            >
              {isLatitudeActive ? activeText : noneText}
            </p>
          ) : (
            <Skeleton />
          )}
        </MDBCol>
      </MDBRow>
    </>
  );
};

export default AddOnsInformation;

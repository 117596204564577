import React, { useEffect, useState } from "react";
import {
  MDBAlert,
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBView,
} from "mdbreact";
import Sidebar from "../components/Sidebar";
import {
  accountSettingsText,
  addressLabelText,
  changePasswordTitleText,
  currentPasswordLabelText,
  currentPasswordText,
  emailLabelText,
  fieldRequiredText,
  greatText,
  incorrectPasswordText,
  nameLabelText,
  newPasswordConfirmationLabelText,
  newPasswordConfirmationText,
  newPasswordLabelText,
  newPasswordText,
  oopsText,
  passwordsDontMatchMessageText,
  passwordsDontMatchText,
  personalInformationTitleText,
  phoneLabelText,
  saveChangesText,
  stateLabelText,
  updatePasswordErrorMessage,
  updatePasswordSuccessMessage,
  zipLabelText,
} from "../../core/constants/strings";
import { useForm } from "react-hook-form";
import ProgressButton from "../components/ProgressButton";
import { useAuth } from "../../core/hooks/useAuth";
import { isRight } from "../../core/types/Either";
import useGTM from "@elgorditosalsero/react-gtm-hook";
import executeWhenFinishToRender from "../../core/functions/executeWhenFinishToRender";

const AccountSettingsPage = () => {
  const auth = useAuth();
  const { sendDataToGTM } = useGTM();
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSucces] = useState(false);
  const { handleSubmit, register, errors, getValues } = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: true,
    defaultValues: {
      currentPassword: "",
      password: "",
      passwordConfirmation: "",
    },
  });

  const onSubmit = (values) => {
    setSubmitted(true);
    setLoading(true);
    auth
      .updatePassword(values.currentPassword, values.password)
      .then((result) => {
        if (isRight(result)) {
          setError(false);
          setSucces(true);
          sendDataToGTM({ event: "update_password", value: "success" });
        } else {
          setSucces(false);
          setError(true);
          sendDataToGTM({ event: "update_password", value: "error" });
        }
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (auth.user) {
      executeWhenFinishToRender();
    }
  }, [auth.user]);

  return (
    <MDBView className="full">
      <MDBContainer fluid className="h-100">
        <MDBRow className="h-100 align-content-start bg-alabaster">
          <Sidebar className="d-none d-md-flex" />
          <MDBCol className="py-3">
            <h1 className="h2-responsive font-weight-semi-bold text-center mb-3 tk-bree-serif">
              {accountSettingsText}
            </h1>
            <MDBCard>
              <MDBCardBody className="p-3 p-lg-5">
                <MDBRow className="align-items-center mb-3 mb-lg-5">
                  <MDBCol>
                    <h4 className="h4-responsive text-primary mb-0">
                      {personalInformationTitleText}
                    </h4>
                  </MDBCol>
                </MDBRow>
                <MDBRow className="align-items-center">
                  <MDBCol className="col-12 col-sm-6 mb-3">
                    <label
                      htmlFor="current-password"
                      className="text-capitalizee"
                    >
                      {nameLabelText}
                    </label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      className="form-control"
                      value={`${auth.user?.firstName ?? ""} ${
                        auth.user?.lastName ?? ""
                      }`}
                      disabled
                    />
                  </MDBCol>
                  <MDBCol className="col-12 col-sm-6 mb-3">
                    <label
                      htmlFor="current-password"
                      className="text-capitalize"
                    >
                      {phoneLabelText}
                    </label>
                    <input
                      type="text"
                      name="phone"
                      id="phone"
                      className="form-control"
                      value={auth.user?.parsedPhoneNumber}
                      disabled
                    />
                  </MDBCol>
                  <MDBCol className="col-12 col-sm-6 mb-3">
                    <label
                      htmlFor="current-password"
                      className="text-capitalize"
                    >
                      {emailLabelText}
                    </label>
                    <input
                      type="text"
                      name="email"
                      id="email"
                      className="form-control"
                      value={auth.user?.email}
                      disabled
                    />
                  </MDBCol>
                  <MDBCol className="col-12 col-sm-6 mb-3">
                    <label
                      htmlFor="current-password"
                      className="text-capitalize"
                    >
                      {addressLabelText}
                    </label>
                    <input
                      type="text"
                      name="address"
                      id="address"
                      className="form-control"
                      value={auth.user?.address}
                      disabled
                    />
                  </MDBCol>
                  <MDBCol className="col-12 col-sm-6 mb-3">
                    <label
                      htmlFor="current-password"
                      className="text-capitalize"
                    >
                      {stateLabelText}
                    </label>
                    <input
                      type="text"
                      name="state"
                      id="state"
                      className="form-control"
                      value={auth.user?.state}
                      disabled
                    />
                  </MDBCol>
                  <MDBCol className="col-12 col-sm-6 mb-3">
                    <label
                      htmlFor="current-password"
                      className="text-capitalize"
                    >
                      {zipLabelText}
                    </label>
                    <input
                      type="text"
                      name="zip"
                      id="zip"
                      className="form-control"
                      value={auth.user?.zip}
                      disabled
                      formNoValidate={true}
                      required={true}
                    />
                  </MDBCol>
                  <MDBCol className="col-12">
                    <p className="mb-0">
                      To update your personal information, please contact us at{" "}
                      <a
                        className="text-decoration-underline"
                        href="mailto:clientservices@insurancebenefitadministrators.com"
                      >
                        clientservices@insurancebenefitadministrators.com
                      </a>
                    </p>
                  </MDBCol>
                </MDBRow>
              </MDBCardBody>
              <hr className="m-0" />
              <MDBCardBody className="p-0">
                <MDBRow className="align-items-center p-3 p-lg-5">
                  <MDBCol>
                    <h4 className="h4-responsive text-primary mb-0">
                      {changePasswordTitleText}
                    </h4>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol>
                    <form
                      className={`needs-validation ${
                        submitted ? "was-validated" : ""
                      } px-3 px-md-4 px-lg-5`}
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <MDBRow className="align-items-start mb-3 mb-sm-4 mb-xl-5 justify-content-between">
                        <MDBCol className="col-12 col-sm-6 col-md-auto mb-3 mb-xl-0">
                          <label
                            htmlFor="current-password"
                            className="text-capitalize"
                          >
                            {currentPasswordLabelText}
                          </label>
                          <input
                            type="password"
                            name="currentPassword"
                            id="current-password"
                            placeholder={currentPasswordText}
                            className={`form-control${
                              errors.currentPassword ? " is-invalid" : ""
                            }`}
                            ref={register({
                              required: fieldRequiredText,
                            })}
                          />
                          <div className="invalid-feedback">
                            {errors.currentPassword?.message}
                          </div>
                        </MDBCol>
                        <div className="col p-0 d-none d-xl-flex" />
                        <MDBCol className="col-12 col-sm-6 col-md-12 col-lg-auto mb-xl-3">
                          <div data-test="input-group" className="input-group">
                            <div className="input-subgroup w-100 w-md-auto w-lg-auto mb-3 mb-xl-0">
                              <label
                                htmlFor="new-password"
                                className="text-capitalize"
                              >
                                {newPasswordLabelText}
                              </label>
                              <input
                                type="password"
                                name="password"
                                id="new-password"
                                placeholder={newPasswordText}
                                className={`form-control${
                                  errors.currentPassword || errors.password
                                    ? " is-invalid"
                                    : ""
                                }`}
                                ref={register({
                                  required: fieldRequiredText,
                                  pattern: {
                                    value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
                                    message: incorrectPasswordText,
                                  },
                                })}
                              />
                              <div className="invalid-feedback">
                                {errors.password?.message}
                              </div>
                            </div>
                            <div className="input-subgroup w-100 w-md-auto w-lg-auto">
                              <label
                                htmlFor="new-password-confirmation"
                                className="text-capitalize"
                              >
                                {newPasswordConfirmationLabelText}
                              </label>
                              <input
                                type="password"
                                name="passwordConfirmation"
                                id="new-password-confirmation"
                                placeholder={newPasswordConfirmationText}
                                className={`form-control${
                                  errors.currentPassword ||
                                  errors.password ||
                                  errors.passwordConfirmation
                                    ? " is-invalid"
                                    : ""
                                }`}
                                ref={register({
                                  required: fieldRequiredText,
                                  validate: (value) =>
                                    value === getValues("password")
                                      ? true
                                      : passwordsDontMatchText,
                                })}
                              />
                              <div className="invalid-feedback">
                                {errors.passwordConfirmation?.message}
                              </div>
                            </div>
                          </div>
                        </MDBCol>
                        <div className="col p-0 d-none d-xl-flex" />
                        <MDBCol className="col-12 col-md-auto mt-4">
                          <ProgressButton
                            className="btn-md text-capitalize m-0 d-inline"
                            block={true}
                            color="orange"
                            loading={loading}
                            text={saveChangesText}
                          />
                        </MDBCol>
                      </MDBRow>
                    </form>
                    {errors.currentPassword && (
                      <MDBAlert
                        color="danger"
                        className="m-0 d-flex justify-content-center rounded-0"
                      >
                        <p className="my-3">
                          <span className="text-danger font-weight-bold">
                            {oopsText}
                          </span>{" "}
                          Please enter your current password.
                        </p>
                      </MDBAlert>
                    )}
                    {!errors.currentPassword && errors.password && (
                      <MDBAlert
                        color="danger"
                        className="m-0 d-flex flex-column justify-content-center align-items-center rounded-0"
                      >
                        <div className="my-3">
                          <p className="mb-2">
                            <span className="text-danger font-weight-bold">
                              {oopsText}
                            </span>{" "}
                            Your new password needs to:
                          </p>
                          <p className="mb-0">
                            - Be at least 8 characters long
                          </p>
                          <p className="mb-0">
                            - Include both lower and upper case characters
                          </p>
                          <p className="mb-0">- Include at least one number</p>
                        </div>
                      </MDBAlert>
                    )}
                    {!errors.currentPassword &&
                      !errors.password &&
                      errors.passwordConfirmation && (
                        <MDBAlert
                          color="danger"
                          className="m-0 d-flex justify-content-center rounded-0"
                        >
                          <p className="my-3">
                            <span className="text-danger font-weight-bold">
                              {oopsText}
                            </span>{" "}
                            {passwordsDontMatchMessageText}
                          </p>
                        </MDBAlert>
                      )}
                    {error && (
                      <MDBAlert
                        color="danger"
                        className="m-0 d-flex justify-content-center rounded-0"
                      >
                        <p className="my-3">
                          <span className="text-danger font-weight-bold">
                            {oopsText}
                          </span>{" "}
                          {updatePasswordErrorMessage}
                        </p>
                      </MDBAlert>
                    )}
                    {success && (
                      <MDBAlert
                        color="success"
                        className="m-0 d-flex justify-content-center rounded-0"
                      >
                        <p className="my-3">
                          <span className="text-success font-weight-bold">
                            {greatText}
                          </span>{" "}
                          {updatePasswordSuccessMessage}
                        </p>
                      </MDBAlert>
                    )}
                  </MDBCol>
                </MDBRow>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </MDBView>
  );
};

export default AccountSettingsPage;

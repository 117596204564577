import React from "react";
import { useAuth } from "./useAuth";
import { Attestation } from "domain/entity/Attestation";
import di from "di/di";
import { GetAttestationParams } from "domain/useCase/GetAttestation";
import { isRight } from "core/types/Either";
import { SubmitAttestationParams } from "domain/useCase/SubmitAttestation";

/**
 * A hook to get and submit document attestations
 * @returns An object containing the current attestation, functions to get and submit attestations, and request status values
 */
export function useAttestation() {
  const auth = useAuth();
  const [attestation, setAttestation] = React.useState<Attestation | null>(
    null
  );
  const [gettingAttestation, setGettingAttestation] = React.useState(false);
  const [submittingAttestation, setSubmittingAttestation] = React.useState(
    false
  );

  const getAttestation = () => {
    setGettingAttestation(true);

    return di.getAttestation
      .execute(new GetAttestationParams(auth.user.id))
      .then((result) => {
        if (isRight(result)) {
          setAttestation(result.value);
        }
        setGettingAttestation(false);
        return result;
      });
  };

  const submitAttestation = () => {
    setSubmittingAttestation(true);

    return di.submitAttestation
      .execute(new SubmitAttestationParams(auth.user.id))
      .then((result) => {
        if (isRight(result)) {
          setAttestation(result.value);
        }
        setGettingAttestation(false);
        return result;
      });
  };

  React.useEffect(() => {
    if (auth.user && !gettingAttestation && !attestation) {
      getAttestation();
    }
  }, [auth.user, gettingAttestation]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    attestation,
    getAttestation,
    gettingAttestation,
    submitAttestation,
    submittingAttestation,
  };
}

import { useState } from "react";
import { isRight } from "../types/Either";
import di from "../../di/di";
import downloadBlob from "../functions/downloadBlob";
import { GetDocumentParams } from "../../domain/useCase/GetDocument";

export function useGetDocument() {
  const [getting, setGetting] = useState(false);

  const get = (
    id?: number,
    appId?: string,
    documentName?: string,
    isLegacyApp?: boolean
  ) => {
    setGetting(true);

    return di.getDocument
      .execute(new GetDocumentParams(id, appId, isLegacyApp))
      .then((result) => {
        if (isRight(result)) {
          downloadBlob(
            result.value,
            `${id}_${documentName
              ?.toUpperCase()
              .replace(" ", "_")}_DOCUMENT.pdf`
          );
        }

        setGetting(false);

        return result;
      });
  };

  return {
    get,
    getting,
  };
}

import {
  changePasswordUrl,
  resetPasswordUrl,
  updatePasswordUrl,
} from "../../core/constants/endpoints";
import HttpClient from "../../core/helpers/HttpClient";

export abstract class PasswordRemoteDataSource {
  abstract reset(email: string): Promise<any>;

  abstract change(token: string, password: string): Promise<any>;

  abstract update(currentPassword: string, newPassword: string): Promise<any>;
}

export class PasswordRemoteDataSourceImpl extends PasswordRemoteDataSource {
  httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    super();
    this.httpClient = httpClient;
  }

  reset = (email: string) =>
    this.httpClient.request(resetPasswordUrl, {
      data: { email },
    });

  change = (token: string, password: string) =>
    this.httpClient.request(changePasswordUrl, {
      headers: { Authorization: `Bearer ${token}` },
      data: { newPassword: password },
    });

  update = (currentPassword: string, newPassword: string) =>
    this.httpClient.request(updatePasswordUrl, {
      data: {
        currentPassword: currentPassword,
        newPassword: newPassword,
      },
    });
}

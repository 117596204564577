import { applicationsUrl, ibaLinkUrl } from "../../core/constants/endpoints";
import { ApplicationModel } from "../model/ApplicationModel";
import HttpClient from "../../core/helpers/HttpClient";

export abstract class ApplicationRemoteDataSource {
  abstract getApplication(): Promise<ApplicationModel>;

  abstract getIbaLink(appId?: number, productType?: string): Promise<string>;
}

export class ApplicationRemoteDataSourceImpl extends ApplicationRemoteDataSource {
  httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    super();
    this.httpClient = httpClient;
  }

  getApplication = () =>
    this.httpClient.request(applicationsUrl, {
      factory: (data) => ApplicationModel.fromJson(data.apps[0]),
    });

  getIbaLink = (appId?: number, productType?: string) =>
    this.httpClient.request<string>(ibaLinkUrl, {
      data: {
        appId: appId,
        productType: productType,
      },
    });
}

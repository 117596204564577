import UseCase from "../../core/useCase/UseCase";
import { PasswordRepository } from "../repository/PasswordRepository";

export default class ChangePassword extends UseCase<any, ChangePasswordParams> {
  repository: PasswordRepository;

  constructor(repository: PasswordRepository) {
    super();
    this.repository = repository;
  }

  execute = (params: ChangePasswordParams) =>
    this.repository.change(params.token, params.password);
}

export class ChangePasswordParams {
  token: string;
  password: string;

  constructor(token: string, password: string) {
    this.token = token;
    this.password = password;
  }
}

export type Either<A, B> = Left<A> | Right<B>;

export interface Left<A> {
  value: A;
  tag: "left";
}

export interface Right<B> {
  value: B;
  tag: "right";
}

export const Left = <A>(val: A): Left<A> => ({ value: val, tag: "left" });

export const Right = <B>(val: B): Right<B> => ({ value: val, tag: "right" });

export const isLeft = <A>(val: any): val is Left<A> =>
  (val as Left<A>).tag === "left";

export const isRight = <B>(val: any): val is Right<B> =>
  (val as Right<B>).tag === "right";

export class AddOn {
  readonly id: number;
  readonly type?: string;
  readonly active?: boolean;
  readonly productType?: string;
  readonly benefits?: string[];
  readonly appId?: number;
  readonly packageName?: string;
  readonly policyId?: string;
  readonly isLegacyApp?: boolean;
  readonly expirationDate?: string;

  constructor(
    id: number,
    type?: string,
    active?: boolean,
    productType?: string,
    benefits?: string[],
    appId?: number,
    packageName?: string,
    policyId?: string,
    isLegacyApp?: boolean,
    expirationDate?: string
  ) {
    this.id = id;
    this.expirationDate = expirationDate;
    this.type = type;
    this.active = active;
    this.productType = productType;
    this.benefits = benefits;
    this.appId = appId;
    this.packageName = packageName;
    this.policyId = policyId;
    this.isLegacyApp = isLegacyApp;
  }
}

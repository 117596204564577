export const isLocalEnvironment = process.env.NODE_ENV === "development";

export const envPrefix: string = "#{ENV_PREFIX}";

export let environment = isLocalEnvironment
  ? "qa."
  : envPrefix.toString().trim()
  ? `${envPrefix}.`
  : "";

environment = environment !== "." ? environment : "";

export const isProduction = environment === "";

const ENABLE_ATTESTATION_LOCAL = true;
const ENABLE_ATTESTATION_NON_PRODUCTION = true;
const ENABLE_ATTESTATION_PRODUCTION = true;

/**
 * Determines whether attestation checks should be on or off
 * @returns A boolean based on the above flags and the current environment
 */
function determineAttestation() {
  if (isLocalEnvironment) return ENABLE_ATTESTATION_LOCAL;
  if (isProduction) return ENABLE_ATTESTATION_PRODUCTION;
  return ENABLE_ATTESTATION_NON_PRODUCTION;
}

export const ENABLE_ATTESTATION = determineAttestation();

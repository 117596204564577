import { documentDownloadUrl } from "../../core/constants/endpoints";
import HttpClient from "../../core/helpers/HttpClient";

export abstract class IdCardRemoteDataSource {
  abstract getIdCard(
    appId?: number,
    productType?: string,
    isLegacyApp?: boolean
  ): Promise<Blob>;
}

export class IdCardRemoteDataSourceImpl extends IdCardRemoteDataSource {
  httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    super();
    this.httpClient = httpClient;
  }

  getIdCard = (appId?: number, productType?: string, isLegacyApp?: boolean) =>
    this.httpClient.request(documentDownloadUrl, {
      data: {
        appId: appId,
        documentType: "PIVOT_ID_CARD",
        productType: productType,
        isLegacyApp: isLegacyApp,
      },
      responseType: "blob",
      factory: (data) => new Blob([data], { type: "application/pdf" }),
    });
}

import { UserRepository } from "../../domain/repository/UserRepository";
import { Either, Left, Right } from "../../core/types/Either";
import { UserModel } from "../model/UserModel";
import { UserRemoteDataSource } from "../dataSource/UserRemoteDataSource";
import { UserLocalDataSource } from "../dataSource/UserLocalDataSource";
import { JwtHelper } from "../../core/helpers/JwtHelper";
import { SessionExpiredError } from "../../core/error/error";

export class UserRepositoryImpl extends UserRepository {
  remoteDataSource: UserRemoteDataSource;
  localDataSource: UserLocalDataSource;
  jwtHelper: JwtHelper;

  constructor(
    remoteDataSource: UserRemoteDataSource,
    localDataSource: UserLocalDataSource,
    jwtHelper: JwtHelper
  ) {
    super();
    this.remoteDataSource = remoteDataSource;
    this.localDataSource = localDataSource;
    this.jwtHelper = jwtHelper;
  }

  async login(email: string, password: string): Promise<Either<Error, string>> {
    try {
      return Right(
        await this.remoteDataSource.login(email, password).then((result) => {
          this.localDataSource.setToken(result);
          return result;
        })
      );
    } catch (e) {
      return Left(e);
    }
  }

  async getUser(): Promise<Either<Error, UserModel>> {
    try {
      const token = this.localDataSource.getToken();
      return this.jwtHelper.isTokenAlive(token)
        ? Right(await this.remoteDataSource.getUserByToken(token))
        : Left(new SessionExpiredError());
    } catch (e) {
      return Left(e);
    }
  }

  async getUserByToken(token: string): Promise<Either<Error, UserModel>> {
    try {
      return this.jwtHelper.isTokenAlive(token)
        ? Right(
            await this.remoteDataSource.getUserByToken(token).then((result) => {
              this.localDataSource.setToken(token);
              return result;
            })
          )
        : Left(new SessionExpiredError());
    } catch (e) {
      return Left(e);
    }
  }

  async logout(): Promise<Either<Error, void>> {
    try {
      this.remoteDataSource.logout();
      return Right(this.localDataSource.unsetToken());
    } catch (e) {
      return Left(e);
    }
  }
}

import { Application } from "../../domain/entity/Application";
import { MemberModel } from "./MemberModel";
import { AddOnModel } from "./AddOnModel";
import { BenefitModel } from "./BenefitModel";

export class ApplicationModel extends Application {
  static fromJson = (json) =>
    new ApplicationModel(
      json?.id,
      json?.expirationDate,
      json?.appId,
      json?.carrierId,
      json?.productType,
      json?.packageName,
      json?.type,
      json?.state,
      json?.active,
      json?.addOns?.map((it) => AddOnModel.fromJson(it)),
      json?.members?.map((it) => MemberModel.fromJson(it)),
      json?.benefitJson ? BenefitModel.fromJson(json?.benefitJson) : undefined,
      json?.policyId,
      json?.agentId,
      json?.agencyId,
      json?.isLegacyApp,
      json?.agentPhone,
      json?.agentPhoneMobile,
      json?.networkProvider,
      json?.ibaLink,
      json?.daysToExpired,
      json?.email,
      json?.zip,
      json?.session,
      json?.planId,
      json?.effectiveDate,
      json?.orderDate,
      json?.channelName?.toLowerCase()
    );
}

import UseCase, { NoParams } from "../../core/useCase/UseCase";
import { Application } from "../entity/Application";
import { ApplicationRepository } from "../repository/ApplicationRepository";

export default class GetApplication extends UseCase<Application, NoParams> {
  repository: ApplicationRepository;

  constructor(repository: ApplicationRepository) {
    super();
    this.repository = repository;
  }

  execute = () => this.repository.getApplication();
}

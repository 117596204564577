import UseCase from "../../core/useCase/UseCase";
import { User } from "../entity/User";
import { UserRepositoryImpl } from "../../data/repository/UserRepositoryImpl";

export default class GetUserByToken extends UseCase<
  User,
  GetUserByTokenParams
> {
  repository: UserRepositoryImpl;

  constructor(repository: UserRepositoryImpl) {
    super();
    this.repository = repository;
  }

  execute = (params: GetUserByTokenParams) =>
    this.repository.getUserByToken(params.token);
}

export class GetUserByTokenParams {
  token: string;

  constructor(token: string) {
    this.token = token;
  }
}

import Plan from "./Plan";
import {
  cignaUrl,
  dentalDoctorUrl,
  firstHealthUrl,
  visionDoctorUrl,
} from "../../core/constants/endpoints";
import { ReactComponent as MedicalIcon } from "../../assets/medical.svg";
import { ReactComponent as DentalIcon } from "../../assets/dental.svg";
import { ReactComponent as VisionIcon } from "../../assets/vision.svg";
import { ReactComponent as LatitudeIcon } from "../../assets/latitude.svg";
import Skeleton from "react-loading-skeleton";
import React, { useState, useEffect } from "react";
import { useAuth } from "../../core/hooks/useAuth";
import { useUrlQuery } from "../../core/hooks/useUrlQuery";
import { isProduction } from "../../core/constants/environment";

const PlanList = () => {
  const auth = useAuth();
  const urlQuery = useUrlQuery();
  const adminSso = urlQuery.get("admin_sso") === "true";

  const [openItem, setOpenItem] = useState("medical");

  function toggleOpen(collapseID) {
    setOpenItem(openItem !== collapseID ? collapseID : "");
  }

  useEffect(() => {
    if (!auth.gettingApplication && !auth.gettingQuote) {
      if (auth.quotes && openItem === "medical") {
        const timeoutId = setTimeout(() => {
          toggleOpen("");
        }, 500);
        return () => clearTimeout(timeoutId);
      }
    }
    return () => void 0;
  }, [auth.gettingApplication, auth.gettingQuote]);

  return (
    <>
      {!auth.gettingApplication ? (
        <>
          <Plan
            id="medical"
            isOpen={openItem}
            onToggleOpen={() => toggleOpen("medical")}
            displayTelemedicineOption={
              auth.application?.isTelemedicineAvailable
            }
            displayPointHealthFooter={
              auth.application?.isPointHealthFooterAvailable
            }
            productType={auth.application?.productType}
            type={auth.application?.type}
            packageName={auth.application?.packageName}
            appId={auth.application?.appId}
            myProductId={auth.application?.appId}
            benefits={auth.application?.benefitList}
            findADoctorUrl={
              auth.application?.productType === "DENTAL"
                ? dentalDoctorUrl
                : auth.application?.productType === "VISION"
                ? visionDoctorUrl
                : auth.application?.productType === "LATITUDE"
                ? ""
                : auth.application?.networkProvider !== "any-provider" &&
                  auth.application?.networkProvider !== undefined
                ? auth.application?.networkProvider === "cigna"
                  ? cignaUrl
                  : firstHealthUrl
                : ""
            }
            canAccessClaims={
              (auth.user?.loggedInFromAdmin && adminSso) ||
              (!auth.user?.loggedInFromAdmin && isProduction)
            }
            icon={
              auth.application?.productType === "DENTAL" ? (
                <DentalIcon />
              ) : auth.application?.productType === "VISION" ? (
                <VisionIcon />
              ) : auth.application?.productType === "LATITUDE" ? (
                <LatitudeIcon />
              ) : (
                <MedicalIcon />
              )
            }
          />
          {auth.application?.dentalPlan && (
            <Plan
              id="dental"
              isOpen={openItem}
              onToggleOpen={() => toggleOpen("dental")}
              productType={auth.application?.dentalPlan?.productType}
              packageName={auth.application?.dentalPlan?.packageName}
              appId={auth.application?.appId}
              myProductId={auth.application?.dentalPlan?.policyId}
              benefits={auth.application?.dentalPlan?.benefits}
              findADoctorUrl={dentalDoctorUrl}
              icon={<DentalIcon />}
              canAccessClaims={
                (auth.user?.loggedInFromAdmin && adminSso) ||
                (!auth.user?.loggedInFromAdmin && isProduction)
              }
            />
          )}
          {auth.application?.visionPlan && (
            <Plan
              id="vision"
              isOpen={openItem}
              onToggleOpen={() => toggleOpen("vision")}
              productType={auth.application?.visionPlan?.productType}
              packageName={auth.application?.visionPlan?.packageName}
              appId={auth.application?.appId}
              myProductId={auth.application?.visionPlan?.policyId}
              benefits={auth.application?.visionPlan?.benefits}
              findADoctorUrl={visionDoctorUrl}
              icon={<VisionIcon />}
              canAccessClaims={
                (auth.user?.loggedInFromAdmin && adminSso) ||
                (!auth.user?.loggedInFromAdmin && isProduction)
              }
            />
          )}
          {auth.application?.latitudePlan && (
            <Plan
              id="latitude"
              isOpen={openItem}
              onToggleOpen={() => toggleOpen("latitude")}
              productType={auth.application?.latitudePlan?.productType}
              packageName={auth.application?.latitudePlan?.packageName}
              appId={auth.application?.appId}
              myProductId={auth.application?.latitudePlan?.policyId}
              benefits={auth.application?.latitudePlan?.benefits}
              icon={<LatitudeIcon />}
            />
          )}
        </>
      ) : (
        <>
          <Skeleton height="4.56rem" className="mb-3" />
          <Skeleton height="4.56rem" className="mb-3" />
          <Skeleton height="4.56rem" className="mb-3" />
          <Skeleton height="4.56rem" className="mb-3" />
        </>
      )}
    </>
  );
};

export default PlanList;

import { Either, Left, Right } from "../../core/types/Either";
import { Document } from "../../domain/entity/Document";
import { DocumentRepository } from "../../domain/repository/DocumentRepository";
import { DocumentRemoteDataSource } from "../dataSource/DocumentRemoteDataSource";

export class DocumentRepositoryImpl extends DocumentRepository {
  remoteDataSource: DocumentRemoteDataSource;

  constructor(remoteDataSource: DocumentRemoteDataSource) {
    super();
    this.remoteDataSource = remoteDataSource;
  }

  async getDocuments(): Promise<Either<Error, Document[]>> {
    try {
      return Right(await this.remoteDataSource.getDocuments());
    } catch (e) {
      return Left(e);
    }
  }

  async getDocument(
    id?: number,
    appId?: string,
    isLegacyApp?: boolean
  ): Promise<Either<Error, Blob>> {
    try {
      return Right(
        await this.remoteDataSource.getDocument(id, appId, isLegacyApp)
      );
    } catch (e) {
      return Left(e);
    }
  }
}

import { Either } from "core/types/Either";
import { Attestation } from "domain/entity/Attestation";

/**
 * A repository for Attestations
 * @param getAttestation A function returning an Either<Error, Attestation> which gets an Attestation
 * @param submitAttestation A function returning an Either<Error, Attestation> which submits an Attestation
 */
export abstract class AttestationRepository {
  abstract getAttestation(userId?: number): Promise<Either<Error, Attestation>>;
  abstract submitAttestation(
    userId?: number
  ): Promise<Either<Error, Attestation>>;
}

import { Either, Left, Right } from "core/types/Either";
import { AttestationRemoteDataSource } from "data/dataSource/AttestationRemoteDataSource";
import { Attestation } from "domain/entity/Attestation";
import { AttestationRepository } from "domain/repository/AttestationRepository";

/**
 * An implementation of the abstract AttestationRepository
 */
export class AttestationRepositoryImpl extends AttestationRepository {
  remoteDataSource: AttestationRemoteDataSource;

  constructor(remoteDataSource: AttestationRemoteDataSource) {
    super();
    this.remoteDataSource = remoteDataSource;
  }

  async getAttestation(userId: number): Promise<Either<Error, Attestation>> {
    try {
      return Right(await this.remoteDataSource.getAttestation(userId));
    } catch (e) {
      return Left(e);
    }
  }

  async submitAttestation(userId: number): Promise<Either<Error, Attestation>> {
    try {
      return Right(await this.remoteDataSource.submitAttestation(userId));
    } catch (e) {
      return Left(e);
    }
  }
}

import { Either } from "../../core/types/Either";

export abstract class PasswordRepository {
  abstract reset(email: string): Promise<Either<Error, any>>;

  abstract change(token: string, password: string): Promise<Either<Error, any>>;

  abstract update(
    currentPassword: string,
    newPassword: string
  ): Promise<Either<Error, any>>;
}

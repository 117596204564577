import { useState } from "react";
import di from "../../di/di";
import { GetIbaLinkParams } from "../../domain/useCase/GetIbaLink";

export function useIbaLink() {
  const [getting, setGetting] = useState(false);

  const get = (appId?: number, productType?: string) => {
    setGetting(true);

    return di.getIbaLink
      .execute(new GetIbaLinkParams(appId, productType))
      .then((result) => {
        setGetting(false);
        return result;
      });
  };

  return {
    get,
    getting,
  };
}

import { ContactInformation } from "../../domain/entity/ContactInformation";

export class ContactInformationModel extends ContactInformation {
  static fromJson = (json) =>
    new ContactInformationModel(
      json?.id,
      json?.category,
      json?.emailTo,
      json?.emailToName,
      json?.phone,
      json?.phoneName,
      json?.option
    );
}

import {
  MDBCol,
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownMenu,
  MDBDropdownToggle,
} from "mdbreact";
import { moreText } from "../../core/constants/strings";
import { Member } from "../../domain/entity/Member";
import Skeleton from "react-loading-skeleton";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../core/hooks/useAuth";

const MembersDropdown = () => {
  const auth = useAuth();
  const [member, setMember] = useState({} as Member);

  useEffect(() => {
    if (auth.application?.members?.length > 0) {
      setMember(
        auth.application?.members?.sort((it) =>
          it.relationship === "PRIMARY" ? 0 : 1
        )[0]
      );
    }
  }, [auth.application]);

  return (
    <MDBCol size="10" className="col-md-12 col-lg col-xl-10">
      {!auth.gettingApplication ? (
        auth.application?.members?.length > 1 ? (
          <MDBDropdown>
            <MDBDropdownToggle
              caret
              color="white"
              className="btn-block btn-outline-light-grey d-flex align-items-center text-capitalize"
              id="dependents-dropdown"
            >
              <span
                id="main-member-name"
                className="main-member-name text-truncate font-weight-bold black-text"
              >
                {member?.firstName || ""} {member?.lastName}
              </span>
              <span className="ml-auto silver-text">
                {moreText.replace(
                  "{amount}",
                  ((auth.application?.members?.length || 1) - 1).toString()
                )}
              </span>
            </MDBDropdownToggle>
            <MDBDropdownMenu color="primary" className="dropdown-custom w-100">
              {auth.application?.members?.map((it: Member) => (
                <MDBDropdownItem
                  key={it.id}
                  onClick={() => setMember(it)}
                  className={`dependant-member-name text-capitalize${
                    it.id === member?.id ? " active" : ""
                  }`}
                >
                  {it.firstName} {it.lastName}
                </MDBDropdownItem>
              )) || <div />}
            </MDBDropdownMenu>
          </MDBDropdown>
        ) : (
          <p
            id="single-member-name"
            className="main-member-name m-0 font-weight-semi-bold"
          >
            {member?.firstName || ""} {member?.lastName}
          </p>
        )
      ) : (
        <Skeleton height="2.85rem" />
      )}
    </MDBCol>
  );
};

export default MembersDropdown;

import UseCase, { NoParams } from "../../core/useCase/UseCase";
import { Quote } from "../entity/Quote";
import { QuoteRepository } from "../repository/QuoteRepository";

export default class GetQuote extends UseCase<Array<Quote>, NoParams> {
  repository: QuoteRepository;

  constructor(repository: QuoteRepository) {
    super();
    this.repository = repository;
  }

  execute = () => this.repository.getQuotes();
}

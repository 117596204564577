import { useAttestation } from "core/hooks/useAttestation";
import { useAuth } from "core/hooks/useAuth";
import { MDBListGroup, MDBModal, MDBModalBody, MDBModalFooter } from "mdbreact";
import React from "react";
import styles from "../scss/attestation.module.scss";
import ProgressButton from "./ProgressButton";
import { ENABLE_ATTESTATION } from "core/constants/environment";
import { useDocument } from "core/hooks/useDocument";
import AttestationDocumentLinks from "./AttestationDocumentLinks";

/**
 * Renders an attestation modal that displays if the user has not yet submitted an attestation
 * Can only be closed by clicking "Agree"
 */
const AttestationModal = () => {
  const auth = useAuth();
  const {
    attestation,
    gettingAttestation,
    submitAttestation,
    submittingAttestation,
  } = useAttestation();

  const { documents, gettingDocuments } = useDocument();

  const [hasAttested, setHasAttested] = React.useState(false);

  React.useEffect(() => {
    if (attestation && attestation.attested) {
      setHasAttested(true);
    }
  }, [attestation]);

  return (
    <MDBModal
      isOpen={
        // Only show modal if:
        ENABLE_ATTESTATION && // Feature flag is turned on
        !hasAttested && // User has not yet attested
        !gettingAttestation && // Attestation status has loaded
        auth.user && // Authorized user has loaded (should prevent admin portal flash error)
        !auth.user?.loggedInFromAdmin && // User is not logged in via the admin portal
        // There are current or upcoming documents on the policy
        documents?.filter(
          (doc) => doc.status === "UPCOMING" || doc.status === "CURRENT"
        ).length !== 0
      }
      inline={false}
      noClickableBodyWithoutBackdrop={false}
      disableBackdrop
      overflowScroll={false}
      centered
      size="lg"
    >
      <MDBModalBody>
        <p>
          In order to proceed, you must acknowledge that you have reviewed the
          following documents and affirm that your (and/or your dependent's)
          information on the application form is complete and accurate.
        </p>
        <MDBListGroup className="list-group-flush border-top border-left border-right border-bottom">
          <AttestationDocumentLinks
            documents={documents}
            documentTypes={[
              "ENROLLMENT_FORM",
              "SCHEDULE_OF_BENEFITS",
              "CERTIFICATE_OF_INSURANCE",
              "AMENDATORY_ENDORSEMENT_RIDER",
              "PIVOT_ID_CARD",
            ]}
          />
        </MDBListGroup>
      </MDBModalBody>
      <MDBModalFooter>
        <p className={styles.mb20}>
          I/we have read these documents and have verified that all the
          information provided in them is completed, true, and correct
        </p>
        <ProgressButton
          color="orange"
          block
          className="btn-md font-weight-semi-bold text-capitalize mb-3 px-3"
          text="Agree"
          onClick={submitAttestation}
          loading={submittingAttestation || gettingDocuments}
        ></ProgressButton>
      </MDBModalFooter>
    </MDBModal>
  );
};

export default AttestationModal;

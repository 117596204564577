import UseCase from "core/useCase/UseCase";
import { Attestation } from "domain/entity/Attestation";
import { AttestationRepository } from "domain/repository/AttestationRepository";

/**
 * A use case for getting attestations that can be called as part of di
 */
export default class GetAttestation extends UseCase<
  Attestation,
  GetAttestationParams
> {
  repository: AttestationRepository;

  constructor(repository: AttestationRepository) {
    super();
    this.repository = repository;
  }

  execute = (params: GetAttestationParams) =>
    this.repository.getAttestation(params.userId);
}

/**
 * Parameters for getting an attestation
 * @param userId The ID of the user for which an attestation will be pulled
 */
export class GetAttestationParams {
  userId?: number;

  constructor(userId?: number) {
    this.userId = userId;
  }
}

import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBCollapse,
  MDBIcon,
  MDBRow,
} from "mdbreact";
import {
  appIdText,
  downloadAllDocumentsText,
  effectiveDateText,
  expirationDateText,
  notAppliesText,
  seeAllDocumentsText,
  seeLessDocumentsText,
  fulfillmentDocsNotAvailableText,
} from "../../core/constants/strings";
import React, { FunctionComponent } from "react";
import useToggle from "react-use-toggle";
import { Document } from "../../domain/entity/Document";
import DocumentItem from "./DocumentItem";
import moment from "moment-timezone";

interface DocumentsProps {
  productType?: string;
  planId?: string;
  status?: string;
  documents?: Document[];
  id?: string;
  appId?: string;
  expirationDate?: string;
  effectiveDate?: string;
  isOpen?: string;
  icon?: any;
  onToggleOpen?: () => void;
  onDownloadError?: () => void;
}

const Documents: FunctionComponent<DocumentsProps> = (props) => {
  const [showMore, setShowMore] = useToggle(false);
  const fulfillmentDocsAvailable = !(
    props.documents &&
    props.documents.length === 1 &&
    props.documents[0].id === undefined
  );

  function getDaysPriorToTheEffectiveDate() {
    return props.documents && props.documents[0].daysPriorEffectiveDate;
  }

  function getEffectiveDateToFulfillmentDocs(
    effectiveDate: string | undefined
  ) {
    let effectiveDateToFulfillmentDocs = "";
    if (effectiveDate) {
      let date = moment.tz(effectiveDate, "YYYY-MM-DD", "US/Central");
      date = date.subtract(getDaysPriorToTheEffectiveDate() ?? 0, "days");
      const dateFormatted = date.format("MM/DD/YYYY");
      effectiveDateToFulfillmentDocs = dateFormatted.toString();
    }
    return effectiveDateToFulfillmentDocs;
  }

  return (
    <MDBCard className="mb-3" id={props.productType}>
      <MDBCardBody className="p-0">
        <MDBBtn
          block
          className="btn-white p-3 px-lg-5 z-depth-0 text-align-left text-capitalize text-left rounded-0 rounded-top-left rounded-top-right"
          onClick={() => props.onToggleOpen && props.onToggleOpen()}
          id={`${props.productType}${
            props.isOpen === props.id ? "_collapse" : "_expand"
          }`}
        >
          <MDBRow className="align-items-center">
            <MDBCol size="auto" className="d-flex justify-content-center">
              {props.icon}
            </MDBCol>
            <MDBCol>
              <h4 className="mb-0 font-weight-semi-bold text-primary h4-responsive text-capitalize">
                {props.planId}
              </h4>
              <p className="mb-0">
                <span>
                  {props.status?.toLocaleLowerCase() !== "upcoming"
                    ? expirationDateText
                    : effectiveDateText}
                </span>{" "}
                <strong className="font-weight-semi-bold">
                  {(props.status?.toLocaleLowerCase() !== "upcoming"
                    ? props.expirationDate
                    : props.effectiveDate) || notAppliesText}
                  {(props.status?.toLocaleLowerCase() === "current" &&
                    props.expirationDate) ||
                  (props.status?.toLocaleLowerCase() === "upcoming" &&
                    props.effectiveDate) ? (
                    <span>**</span>
                  ) : (
                    ""
                  )}
                </strong>{" "}
                <span className="silver-text">/</span> {appIdText}{" "}
                <strong className="font-weight-semi-bold">{props.appId}</strong>
              </p>
            </MDBCol>
            <MDBCol size="auto">
              <i
                className={`fa ${
                  props.isOpen === props.id ? "fa-angle-up" : "fa-angle-down"
                } rotate-icon text-primary`}
              />
            </MDBCol>
          </MDBRow>
        </MDBBtn>
      </MDBCardBody>
      {props.isOpen === props.id && <hr className="m-0" />}
      <MDBCollapse id={props.id} isOpen={props.isOpen}>
        {fulfillmentDocsAvailable ? (
          <>
            <MDBCardBody className="px-3 pt-3 px-md-5 pt-md-5 pb-0">
              <MDBRow>
                {props.documents?.slice(0, 4)?.map((it) => (
                  <DocumentItem
                    key={it.id}
                    id={it.id}
                    appId={it.policyId}
                    isLegacApp={it.isLegacyApp}
                    name={it.documentName}
                    productType={it.productType?.toLocaleLowerCase()}
                    onDownloadError={props.onDownloadError}
                  />
                ))}
                {showMore &&
                  props.documents
                    ?.slice(4, props.documents?.length)
                    ?.map((it) => (
                      <DocumentItem
                        key={it.id}
                        id={it.id}
                        appId={it.policyId}
                        isLegacApp={it.isLegacyApp}
                        name={it.documentName}
                        productType={it.productType}
                        onDownloadError={props.onDownloadError}
                      />
                    ))}
              </MDBRow>
            </MDBCardBody>
            <hr className="m-0" />
            <MDBCardBody className="px-3 px-md-5">
              <MDBRow className="align-items-center">
                <MDBCol size="12" className="col-md-auto">
                  <h5 className="m-0 text-right text-md-left">
                    <button
                      className="text-link text-decoration-none text-primary"
                      onClick={setShowMore}
                      id={
                        props.productType +
                        (!showMore ? "_see_all" : "_see_less")
                      }
                    >
                      {!showMore ? seeAllDocumentsText : seeLessDocumentsText}{" "}
                      <MDBIcon
                        icon="arrow-right"
                        className="ml-1 silver-text"
                      />
                    </button>
                  </h5>
                </MDBCol>
                <hr className="col-12 px-0 d-block d-md-none" />
                <MDBCol size="12" className="col-md d-none">
                  <h5 className="m-0 text-right">
                    <button className="text-link text-decoration-none text-primary">
                      <MDBIcon icon="download" className="mr-1" />{" "}
                      {downloadAllDocumentsText}{" "}
                      <MDBIcon
                        icon="arrow-right"
                        className="ml-1 silver-text"
                      />
                    </button>
                  </h5>
                </MDBCol>
              </MDBRow>
            </MDBCardBody>
          </>
        ) : (
          <>
            <MDBCardBody className="px-3 px-md-5">
              <p className="mb-2 red-text text-center">
                {fulfillmentDocsNotAvailableText
                  .replace(
                    "{days}",
                    getDaysPriorToTheEffectiveDate()?.toString() ?? ""
                  )
                  .replace(
                    "{date}",
                    getEffectiveDateToFulfillmentDocs(props.effectiveDate)
                  )}
              </p>
            </MDBCardBody>
          </>
        )}
      </MDBCollapse>
    </MDBCard>
  );
};

Documents.defaultProps = {};

export default Documents;

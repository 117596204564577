import UseCase, { NoParams } from "../../core/useCase/UseCase";
import { DocumentRepository } from "../repository/DocumentRepository";
import { Document } from "../entity/Document";

export default class GetDocuments extends UseCase<Document[], NoParams> {
  repository: DocumentRepository;

  constructor(repository: DocumentRepository) {
    super();
    this.repository = repository;
  }

  execute = () => this.repository.getDocuments();
}

class FormatHelper {
  phoneNumberFormat = (phoneNumber: string) =>
    phoneNumber.length === 10
      ? `+${phoneNumber.substring(0, 3)} ${phoneNumber.substring(
          3,
          6
        )} ${phoneNumber.substring(6, 10)}`
      : phoneNumber;
}

export default FormatHelper;

export class Document {
  readonly id?: number;
  readonly planId?: string;
  readonly productType?: string;
  readonly policyId?: string;
  readonly documentName?: string;
  readonly isLegacyApp?: boolean;
  readonly expirationDate?: string;
  readonly effectiveDate?: string;
  readonly status?: string;
  readonly documentType?: string;
  readonly daysPriorEffectiveDate?: number;

  constructor(
    id?: number,
    planId?: string,
    productType?: string,
    policyId?: string,
    documentName?: string,
    isLegacyApp?: boolean,
    expirationDate?: string,
    effectiveDate?: string,
    status?: string,
    documentType?: string,
    daysPriorEffectiveDate?: number
  ) {
    this.id = id;
    this.planId = planId;
    this.productType = productType;
    this.policyId = policyId;
    this.documentName = documentName;
    this.isLegacyApp = isLegacyApp;
    this.expirationDate = expirationDate;
    this.effectiveDate = effectiveDate;
    this.status = status;
    this.documentType = documentType;
    this.daysPriorEffectiveDate = daysPriorEffectiveDate;
  }
}

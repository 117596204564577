export class Quote {
  productId: number;
  planName: string;
  premium: number;

  constructor(productId: number, planName: string, premium: number) {
    this.productId = productId;
    this.planName = planName;
    this.premium = premium;
  }
}

import UseCase from "../../core/useCase/UseCase";
import { DocumentRepository } from "../repository/DocumentRepository";

export default class GetDocument extends UseCase<Blob, GetDocumentParams> {
  repository: DocumentRepository;

  constructor(repository: DocumentRepository) {
    super();
    this.repository = repository;
  }

  execute = (params: GetDocumentParams) =>
    this.repository.getDocument(params.id, params.appId, params.isLegacyApp);
}

export class GetDocumentParams {
  id?: number;
  appId?: string;
  isLegacyApp?: boolean;

  constructor(appId?: number, productType?: string, isLegacyApp?: boolean) {
    this.id = appId;
    this.appId = productType;
    this.isLegacyApp = isLegacyApp;
  }
}

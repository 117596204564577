import { useEffect } from "react";
import { useAuth } from "./useAuth";
import { useRouter } from "./useRouter";
import { isLeft, isRight } from "../types/Either";
import { useUrlQuery } from "./useUrlQuery";

export function useRequireAuth(redirectUrl = "/login") {
  const auth = useAuth();
  const router = useRouter();
  const urlQuery = useUrlQuery();

  // If get user returns left that means we're not
  // logged in and should redirect.
  useEffect(() => {
    const token = urlQuery.get("token");
    const page = urlQuery.get("page");

    if (token === null) {
      auth.getUser().then((response) => {
        if (isLeft(response)) {
          router.push(redirectUrl);
        }
      });
    } else {
      auth.getUserByToken(token).then((result) => {
        if (isRight(result)) {
          window.location.href = page === "documents" ? "./documents" : "./";
        } else {
          router.push(redirectUrl);
        }
      });
    }
  }, [redirectUrl]); // eslint-disable-line react-hooks/exhaustive-deps
}

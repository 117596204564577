export class Plan {
  id?: number;
  appId?: number;
  productType?: string;
  isLegacyApp?: boolean;
  active?: boolean;

  constructor(
    id?: number,
    appId?: number,
    productType?: string,
    isLegacyApp?: boolean,
    active?: boolean
  ) {
    this.id = id;
    this.appId = appId;
    this.productType = productType;
    this.isLegacyApp = isLegacyApp;
    this.active = active;
  }
}

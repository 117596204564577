export class Member {
  readonly id: number;
  readonly firstName: string;
  readonly lastName: string;
  readonly dob: string;
  readonly gender: string;
  readonly relationship: string;
  readonly age: number;

  constructor(
    id: number,
    firstName: string,
    lastName: string,
    dob: string,
    gender: string,
    relationship: string
  ) {
    this.id = id;
    this.firstName = firstName;
    this.lastName = lastName;
    this.dob = dob;
    this.gender = gender;
    this.relationship = relationship;
    this.age = this.getAge(dob);
  }

  private getAge(dob: string) {
    let age = 0;
    const dateOfBirth = new Date(dob);

    if (dateOfBirth instanceof Date && !Number.isNaN(dateOfBirth.getTime())) {
      const newDate: Date = new Date();

      const today = new Date(
        newDate.getFullYear(),
        newDate.getMonth(),
        newDate.getDate()
      );

      const diff: number = today.getTime() - dateOfBirth.getTime();
      const ageDate: Date = new Date(diff);

      age = Math.abs(ageDate.getUTCFullYear() - 1970);
    }

    return age;
  }
}

import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import LoginPage from "../presentation/pages/LoginPage";
import ForgotPasswordPage from "../presentation/pages/ForgotPasswordPage";
import DashboardPage from "../presentation/pages/DashboardPage";
import { useRequireAuth } from "./hooks/useRequireAuth";
import DocumentsPage from "../presentation/pages/DocumentsPage";
import ChangePasswordPage from "../presentation/pages/ChangePasswordPage";
import HelpPage from "../presentation/pages/HelpPage";
import ErrorPage from "../presentation/pages/ErrorPage";
import AccountSettingsPage from "../presentation/pages/AccountSettingsPage";
import { useRouter } from "./hooks/useRouter";
import useGTM from "@elgorditosalsero/react-gtm-hook";

function PrivateRoute({ component: Component, ...rest }) {
  useRequireAuth();

  return <Route {...rest} render={(props) => <Component {...props} />} />;
}

function Routes() {
  const router = useRouter();
  const { sendDataToGTM } = useGTM();

  useEffect(() => {
    try {
      sendDataToGTM({ event: "pageview", value: router.location });
    } catch (e) {}
  }, [router.location]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Switch>
      <Route exact path="/login" name="Login" component={LoginPage} />
      <Route
        exact
        path="/forgot-password"
        name="Reset Password"
        component={ForgotPasswordPage}
      />
      <Route
        exact
        path="/change-password"
        name="Change Password"
        component={ChangePasswordPage}
      />
      <PrivateRoute exact path="/" name="Dashboard" component={DashboardPage} />
      <PrivateRoute
        exact
        path="/documents"
        name="Documents"
        component={DocumentsPage}
      />
      <PrivateRoute exact path="/help" name="Help" component={HelpPage} />
      <PrivateRoute
        exact
        path="/account-settings"
        name="Acount Settings"
        component={AccountSettingsPage}
      />
      <Route component={ErrorPage} />
    </Switch>
  );
}

export default Routes;

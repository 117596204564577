import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBCollapse,
  MDBIcon,
  MDBRow,
} from "mdbreact";
import {
  accessClaimsAndBillingText,
  accessBillingText,
  appIdText,
  findADentistText,
  findADoctorText,
  findAnEyeDoctorText,
  toLoginMemberPortalText,
  telemedicineText,
  clickHereText,
  telemedicineTitleText,
  conciergeServicesTitleText,
  pointHealthMessage,
  toDownloadAppText,
  claimsAndBillingText,
  BillingText,
  findADoctorMessage,
  clickingHereText,
  shortTermPointHealthMessage,
  toDownloadAppOrCallText,
  pointHealthPhoneNumberText,
} from "../../core/constants/strings";
import React, { FunctionComponent } from "react";
import { useIbaLink } from "../../core/hooks/useIbaLink";
import { isRight } from "../../core/types/Either";
import {
  pointhealthUrl,
  telemedicineUrl,
  helpPhoneUrl,
} from "../../core/constants/endpoints";

interface PlanProps {
  productType?: string;
  packageName?: string;
  type?: string;
  appId?: number;
  myProductId?: number;
  benefits?: string[];
  findADoctorUrl?: string;
  canAccessClaims?: boolean;
  id?: string;
  isOpen?: string;
  icon?: any;
  displayTelemedicineOption?: boolean;
  displayPointHealthFooter?: boolean;
  onToggleOpen?: () => void;
}

const Plan: FunctionComponent<PlanProps> = (props) => {
  const ibaLink = useIbaLink();

  const [filteredBenefits, setFilteredBenefits] = React.useState(
    props.benefits
  );

  React.useEffect(() => {
    // If it's a Latitude plan, filter out and $0 benefits, otherwise do not filter
    if (props.benefits && props.productType === "LATITUDE") {
      setFilteredBenefits(
        props.benefits.filter((item) => !item.includes("$0"))
      );
    } else setFilteredBenefits(props.benefits);
  }, [props.benefits, props.productType]);

  const goToIbaLink = () => {
    if (props.canAccessClaims) {
      ibaLink.get(props.appId, props.productType).then((result) => {
        if (isRight(result)) {
          window.open(result.value, "_blank") ||
            window.location.replace(result.value);
        }
      });
    }
  };

  const goToTelemedicineSite = () => {
    window.open(telemedicineUrl, "_blank") ||
      window.location.replace(telemedicineUrl);
  };

  const goPointhealthSite = () => {
    window.open(pointhealthUrl, "_blank") ||
      window.location.replace(pointhealthUrl);
  };

  return (
    <MDBCard className="mb-3" id={props.id}>
      <MDBCardBody className="p-0">
        <MDBBtn
          block
          className="btn-white p-3 px-lg-5 z-depth-0 text-align-left text-capitalize text-left rounded-0 rounded-top-left rounded-top-right"
          onClick={() => props.onToggleOpen && props.onToggleOpen()}
        >
          <MDBRow className="align-items-center">
            <MDBCol size="auto" className="d-flex justify-content-center">
              {props.icon}
            </MDBCol>
            <MDBCol>
              <h4 className="mb-0 font-weight-semi-bold text-primary h4-responsive">
                {props.packageName}
              </h4>
              <p className="mb-0">
                {appIdText}{" "}
                <strong className="font-weight-semi-bold">
                  {props.myProductId}
                </strong>
              </p>
            </MDBCol>
            <MDBCol size="auto">
              <i
                className={`fa ${
                  props.isOpen === props.id ? "fa-angle-up" : "fa-angle-down"
                } rotate-icon text-primary`}
              />
            </MDBCol>
          </MDBRow>
        </MDBBtn>
      </MDBCardBody>
      {props.isOpen === props.id && <hr className="m-0" />}
      <MDBCollapse id={props.id} isOpen={props.isOpen}>
        <MDBCardBody className="p-5">
          <MDBRow>
            {filteredBenefits?.map((it, index) => (
              <MDBCol
                size="12"
                md="6"
                key={index}
                className={`benefit ${
                  index < (filteredBenefits?.length || 0) - 1 ? "mb-3" : ""
                } ${
                  index < (filteredBenefits?.length || 0) - 2 ? " mb-md-5" : ""
                }`}
                dangerouslySetInnerHTML={{ __html: it }}
              />
            ))}
          </MDBRow>
        </MDBCardBody>
        {props.productType === "MEDICAL" && <hr className="pb-3" />}
        {props.productType &&
          ["VISION", "DENTAL"].includes(props.productType) && (
            <hr className="pb-0" />
          )}
        {props.displayPointHealthFooter &&
          props.productType === "MEDICAL" &&
          props.type !== "FBM" && (
            <>
              <MDBCardBody className="pl-5 pr-5 pt-1 pb-1">
                <MDBCol className="benefit pb-3 p-0">
                  <div>
                    <b>{conciergeServicesTitleText.toUpperCase()}</b>
                    <br />
                    <p className="m-0">
                      {shortTermPointHealthMessage}
                      <button
                        onClick={goPointhealthSite}
                        id="btn-point-health"
                        className="bg-transparent border-0 px-1 text-decoration-underline text-primary"
                      >
                        {clickHereText}
                      </button>
                      {toDownloadAppOrCallText}
                      <a
                        href={helpPhoneUrl.replace(
                          "{phone}",
                          pointHealthPhoneNumberText.replace(/[^\w\s]/gi, "")
                        )}
                        target="_blank"
                        rel="noopener noreferrer"
                        id="btn-point-health"
                        className="bg-transparent border-0 px-1 text-decoration-underline text-primary"
                      >
                        {pointHealthPhoneNumberText}
                      </a>
                      .
                    </p>
                  </div>
                </MDBCol>
              </MDBCardBody>
            </>
          )}
        {props.displayTelemedicineOption && props.productType === "MEDICAL" && (
          <>
            <MDBCardBody className="pl-5 pr-5 pt-1 pb-1">
              <MDBCol className="benefit pb-3 p-0">
                <div>
                  <b>{telemedicineTitleText.toUpperCase()}</b>
                  <br />
                  <p className="m-0">
                    {telemedicineText}
                    <button
                      onClick={goToTelemedicineSite}
                      id="btn-telemedicine"
                      className="bg-transparent border-0 px-1 text-decoration-underline text-primary"
                    >
                      {clickHereText}.
                    </button>
                  </p>
                </div>
              </MDBCol>
            </MDBCardBody>
          </>
        )}
        {props.type === "FBM" && props.productType === "MEDICAL" && (
          <>
            <MDBCardBody className="pl-5 pr-5 pt-1 pb-1">
              <MDBCol className="benefit pb-3 p-0">
                <div>
                  <b>{conciergeServicesTitleText.toUpperCase()}</b>
                  <br />
                  <p className="m-0">
                    {pointHealthMessage}
                    <button
                      onClick={goPointhealthSite}
                      id="btn-point-health"
                      className="bg-transparent border-0 px-1 text-decoration-underline text-primary"
                    >
                      {clickHereText}
                    </button>
                    {toDownloadAppText}.
                  </p>
                </div>
              </MDBCol>
            </MDBCardBody>
          </>
        )}
        {props.productType &&
          ["MEDICAL", "VISION", "DENTAL"].includes(props.productType) && (
            <>
              <MDBCardBody className="pl-5 pr-5 pt-1 pb-1">
                <MDBCol className="benefit pb-3 p-0">
                  <div>
                    <b>
                      {props.productType === "MEDICAL"
                        ? claimsAndBillingText.toUpperCase()
                        : BillingText.toUpperCase()}
                    </b>
                    <br />
                    <p className="m-0">
                      {props.productType === "MEDICAL"
                        ? accessClaimsAndBillingText
                        : accessBillingText}
                      <button
                        onClick={goToIbaLink}
                        id="claims"
                        className="bg-transparent border-0 px-1 text-decoration-underline text-primary"
                      >
                        {clickingHereText}
                      </button>
                      {toLoginMemberPortalText}
                    </p>
                  </div>
                </MDBCol>
              </MDBCardBody>
            </>
          )}
        {props.productType === "MEDICAL" && <div className="pb-3" />}
        {props.findADoctorUrl && (
          <>
            <hr className="m-0" />
            <MDBCardBody className="px-3 px-md-5">
              <MDBRow className="align-items-center">
                {props.productType === "MEDICAL" && (
                  <>
                    <MDBCol size="12" md="8">
                      <p className="m-0">{findADoctorMessage}</p>
                    </MDBCol>
                  </>
                )}
                <MDBCol>
                  <h5 className="m-0 text-right">
                    <a
                      href={props.findADoctorUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-primary"
                      id={`find-${
                        props.productType === "DENTAL"
                          ? "a-dentist"
                          : props.productType === "VISION"
                          ? "an-eye-doctor"
                          : "a-doctor"
                      }`}
                    >
                      {props.productType === "DENTAL"
                        ? findADentistText
                        : props.productType === "VISION"
                        ? findAnEyeDoctorText
                        : findADoctorText}
                      <MDBIcon
                        icon="arrow-right"
                        className="ml-1 silver-text"
                      />
                    </a>
                  </h5>
                </MDBCol>
              </MDBRow>
            </MDBCardBody>
          </>
        )}
      </MDBCollapse>
    </MDBCard>
  );
};

Plan.defaultProps = {};

export default Plan;

import React from "react";
import {
  MDBCol,
  MDBContainer,
  MDBFooter,
  MDBNav,
  MDBNavItem,
  MDBRow,
} from "mdbreact";
import {
  contactUsText,
  copyrightText,
  pivotHealthText,
  privacyPolicyText,
  termsOfServiceText,
} from "../../core/constants/strings";
import {
  contactUsUrl,
  privacyPolicyUrl,
  termsOfServiceUrl,
} from "../../core/constants/endpoints";

const Footer = () => {
  return (
    <MDBFooter color="elegant-color">
      <MDBContainer fluid>
        <MDBRow className="mb-0 py-3 m-0 align-items-center">
          <MDBCol size="12" className="mb-3 col-sm-auto mb-sm-0">
            <MDBNav>
              <MDBNavItem>
                <a
                  href={privacyPolicyUrl}
                  className="nav-link pl-0"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {privacyPolicyText}
                </a>
              </MDBNavItem>
              <MDBNavItem>
                <a
                  href={termsOfServiceUrl}
                  className="nav-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {termsOfServiceText}
                </a>
              </MDBNavItem>
              <MDBNavItem>
                <a
                  href={contactUsUrl}
                  className="nav-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {contactUsText}
                </a>
              </MDBNavItem>
            </MDBNav>
          </MDBCol>
          <MDBCol>
            <p className="mb-0 text-left text-sm-right">
              {copyrightText} &copy; {new Date().getFullYear()}{" "}
              {pivotHealthText}.
            </p>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </MDBFooter>
  );
};

export default Footer;

import React, { FunctionComponent } from "react";
import { callText } from "../../core/constants/strings";
import { MDBBtn, MDBCard, MDBCardBody, MDBCol, MDBRow } from "mdbreact";

interface HelpWidgetProps {
  title?: string;
  description?: string;
  image?: string;
  leftButtonTitle?: string;
  rightButtonTitle?: string;
  leftHref?: string;
  rightHref?: string;
}

const HelpWidget: FunctionComponent<HelpWidgetProps> = (props) => {
  return (
    <MDBCard>
      <MDBCardBody>
        <MDBRow className="align-items-center mb-3">
          <MDBCol size="12" sm="2" className="pr-sm-0 text-center mb-3 mb-sm-0">
            <img
              src={props.image}
              alt="Help"
              className="w-50 w-sm-100 d-inline"
            />
          </MDBCol>
          <MDBCol>
            <h2 className="text-primary mb-1 h2-responsive text-center text-sm-left">
              {props.title}
            </h2>
            <p className="m-0">{props.description}</p>
          </MDBCol>
        </MDBRow>
        <MDBRow className="align-items-center">
          <MDBCol size="12" sm="6" className="pr-sm-0 mb-3 mb-sm-0">
            <MDBBtn
              color="orange"
              block
              outline
              className="btn-md font-weight-semi-bold text-capitalize m-0"
              tag="a"
              id="help-email"
              href={props.leftHref}
              target="_blank"
            >
              {props.leftButtonTitle}
            </MDBBtn>
          </MDBCol>
          <MDBCol size="12" sm="6">
            <MDBBtn
              color="orange"
              block
              outline
              className="btn-md font-weight-semi-bold text-capitalize m-0"
              tag="a"
              id="help-phone"
              href={props.rightHref}
              target="_blank"
            >
              {callText}
              <span className="d-none d-md-inline ml-1">
                {props.rightButtonTitle}
              </span>
            </MDBBtn>
          </MDBCol>
        </MDBRow>
      </MDBCardBody>
    </MDBCard>
  );
};

HelpWidget.defaultProps = {};

export default HelpWidget;

import { Benefit } from "../../domain/entity/Benefit";

export class BenefitModel extends Benefit {
  static fromJson = (json) =>
    new BenefitModel(
      json?.deductible,
      json?.coinsurance,
      json?.lifetimeMax,
      json?.outOfPocketMax,
      json?.majorServices,
      json?.basicServices,
      json?.preventiveDiagnostic,
      json?.benefitYearMaximum,
      json?.orthodontia,
      json?.allowedAmounts,
      json?.message,
      json?.hospitalInjury,
      json?.hospitalConfinement,
      json?.physicianOffice,
      json?.wellnessBenefits,
      json?.genericBrandNamePrescription,
      json?.patientAdvocacyServices
    );
}

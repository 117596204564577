import React, { useEffect } from "react";
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBView,
} from "mdbreact";
import {
  backToHomeText,
  pageNotFoundDescriptionText,
  pageNotFoundTitleText,
} from "../../core/constants/strings";
import { ReactComponent as ErrorIcon } from "../../assets/error.svg";
import executeWhenFinishToRender from "../../core/functions/executeWhenFinishToRender";

const ErrorPage = () => {
  useEffect(() => executeWhenFinishToRender(), []);

  return (
    <MDBView className="full bg-alabaster">
      <MDBContainer className="py-3 py-md-5">
        <MDBCard>
          <MDBCardBody className="py-3 py-md-5">
            <MDBRow className="mb-3">
              <MDBCol className="text-center">
                <ErrorIcon />
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol>
                <h2 className="text-primary mb-1 h2-responsive text-center">
                  {pageNotFoundTitleText}
                </h2>
                <p className="text-center">{pageNotFoundDescriptionText}</p>
              </MDBCol>
            </MDBRow>
            <MDBRow className="align-items-center">
              <MDBCol className="text-center">
                <MDBBtn
                  color="orange"
                  className="btn-md m-0 font-weight-semi-bold text-capitalize px-5"
                  tag="a"
                  href="/"
                >
                  {backToHomeText}
                </MDBBtn>
              </MDBCol>
            </MDBRow>
          </MDBCardBody>
        </MDBCard>
      </MDBContainer>
    </MDBView>
  );
};

export default ErrorPage;

import { Either, Left, Right } from "../../core/types/Either";
import { IdCardRepository } from "../../domain/repository/IdCardRepository";
import { IdCardRemoteDataSource } from "../dataSource/IdCardRemoteDataSource";

export class IdCardRepositoryImpl extends IdCardRepository {
  remoteDataSource: IdCardRemoteDataSource;

  constructor(remoteDataSource: IdCardRemoteDataSource) {
    super();
    this.remoteDataSource = remoteDataSource;
  }

  async getIdCard(
    appId?: number,
    productType?: string,
    isLegacyApp?: boolean
  ): Promise<Either<Error, Blob>> {
    try {
      return Right(
        await this.remoteDataSource.getIdCard(appId, productType, isLegacyApp)
      );
    } catch (e) {
      return Left(e);
    }
  }
}

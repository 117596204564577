import { MDBBtn } from "mdbreact";
import React, { FunctionComponent, SyntheticEvent } from "react";

interface ProgressButtonProps {
  block?: boolean;
  loading?: boolean;
  color?:
    | "amber"
    | "blue-grey"
    | "blue"
    | "brown"
    | "cyan"
    | "danger"
    | "dark-green"
    | "dark"
    | "deep-orange"
    | "deep-purple"
    | "default"
    | "elegant"
    | "green"
    | "grey"
    | "indigo"
    | "info"
    | "light-blue"
    | "light-green"
    | "light"
    | "lime"
    | "mdb-color"
    | "orange"
    | "pink"
    | "primary"
    | "purple"
    | "secondary"
    | "success"
    | "unique"
    | "warning"
    | "red"
    | "yellow";
  className?: string;
  id?: string;
  text?: string;
  onClick?: (event: SyntheticEvent<HTMLButtonElement>) => void;
}

const ProgressButton: FunctionComponent<ProgressButtonProps> = (props) => {
  return (
    <MDBBtn
      color={props.color}
      block={props.block}
      type="submit"
      className={props.className}
      disabled={props.loading}
      onClick={props.onClick}
      id={props.id}
    >
      <span className={`${props.loading ? "d-block" : "d-none"}`}>
        <div
          className="spinner-border font-weight-normal spinner-border-sm align-middle"
          role="status"
        >
          <span className="sr-only">Loading...</span>
        </div>
      </span>
      <span className={props.loading ? "d-none" : "d-inline"}>
        {props.text}
      </span>
    </MDBBtn>
  );
};

ProgressButton.defaultProps = {};

export default ProgressButton;

import UseCase from "../../core/useCase/UseCase";
import { PasswordRepository } from "../repository/PasswordRepository";

export default class UpdatePassword extends UseCase<any, UpdatePasswordParams> {
  repository: PasswordRepository;

  constructor(repository: PasswordRepository) {
    super();
    this.repository = repository;
  }

  execute = (params: UpdatePasswordParams) =>
    this.repository.update(params.currentPassword, params.newPassword);
}

export class UpdatePasswordParams {
  currentPassword: string;
  newPassword: string;

  constructor(currentPassword: string, newPassword: string) {
    this.currentPassword = currentPassword;
    this.newPassword = newPassword;
  }
}

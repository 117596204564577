import { Either, Left, Right } from "../../core/types/Either";
import { ContactInformationRepository } from "../../domain/repository/ContactInformationRepository";
import { ContactInformationRemoteDataSource } from "../dataSource/ContactInformationRemoteDataSource";
import { ContactInformation } from "../../domain/entity/ContactInformation";

export class ContactInformationRepositoryImpl extends ContactInformationRepository {
  remoteDataSource: ContactInformationRemoteDataSource;

  constructor(remoteDataSource: ContactInformationRemoteDataSource) {
    super();
    this.remoteDataSource = remoteDataSource;
  }

  async getContactInformation(): Promise<Either<Error, ContactInformation>> {
    try {
      return Right(await this.remoteDataSource.getContactInformation());
    } catch (e) {
      return Left(e);
    }
  }
}

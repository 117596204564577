import { MDBCol, MDBRow } from "mdbreact";
import React, { FunctionComponent } from "react";
import {
  fbmEffectiveDateDisclaimerText,
  membersText,
  stmEffectiveDateDisclaimerText,
  yourCardsText,
} from "../../core/constants/strings";
import SidebarBottomMenu from "./SidebarBottomMenu";
import IdCardDropdown from "./IdCardDropdown";
import EnrollAgainButton from "./EnrollAgainButton";
import PlanInformation from "./PlanInformation";
import AddOnsInformation from "./AddOnsInformation";
import MembersInformation from "./MembersInformation";
import { useAuth } from "../../core/hooks/useAuth";
import Sticky from "react-stickynode";

interface SidebarProps {
  className?: string;
}

const Sidebar: FunctionComponent<SidebarProps> = (props) => {
  const auth = useAuth();

  return (
    <MDBCol
      size="12"
      md="3"
      className={`h-auto h-md-100 pt-3 white flex-column justify-content-between ${props.className}`}
      id="sidebar"
    >
      <Sticky top={16} bottomBoundary="#bottom-menu">
        <MDBRow>
          {!auth.errorGettingApplication && (
            <MDBCol className="px-3">
              <MDBRow>
                <MDBCol>
                  <p className="text-uppercase">
                    <small>
                      <strong className="font-weight-bold">
                        {membersText}
                      </strong>{" "}
                      primary, you
                    </small>
                  </p>
                </MDBCol>
              </MDBRow>
              <MembersInformation />
              <hr />
              <PlanInformation />
              <AddOnsInformation />
              <EnrollAgainButton />
              {auth.application?.active &&
                (auth.application?.type === "FBM" ? (
                  <p className="silver-text mb-0">
                    {fbmEffectiveDateDisclaimerText}
                  </p>
                ) : (
                  <p className="silver-text mb-0">
                    {stmEffectiveDateDisclaimerText}
                  </p>
                ))}
              <hr />
              <MDBRow>
                <MDBCol>
                  <p className="text-uppercase">
                    <small>
                      <strong className="font-weight-bold">
                        {yourCardsText}
                      </strong>
                    </small>
                  </p>
                </MDBCol>
              </MDBRow>
              <IdCardDropdown />
            </MDBCol>
          )}
        </MDBRow>
      </Sticky>
      <SidebarBottomMenu />
    </MDBCol>
  );
};

Sidebar.defaultProps = {};

export default Sidebar;
